import React, { Fragment, useState } from 'react';
import moment from 'moment';
import {
  Button, Checkbox, DatePicker, Input, InputNumber, Popconfirm, Radio, Select,
} from 'antd';

import { DATE_FORMAT } from '../../constants/time';

import './Form.scss';

const FormRow = ({
  isDeletable = false,
  isEditable = false,
  fields = [],
  label = '',
  handleChangeRowField = () => {},
  handleDeleteField = () => {},
}) => {
  const [fieldValues, setFieldValues] = useState({});

  const handleChange = (selectedField, value) => {
    const updatedRows = fields.map((field) => {
      if (field.name === selectedField.name) {
        field.value = value;
      }

      return field;
    });

    setFieldValues(updatedRows);
    handleChangeRowField(updatedRows);
  };

  const disableDate = (current, allowedDates) => {
    if (!allowedDates || !current) {
      return false;
    }

    const formattedCurrentDate = current.format(DATE_FORMAT);
    return allowedDates.indexOf(formattedCurrentDate) === -1;
  };

  const setAdditionalInputProps = (field) => {
    const inputProps = {};

    switch (field.type) {
      case 'number': {
        inputProps.onChange = (value) => handleChange(field, value);
        inputProps.step = 1;
        inputProps.min = field.min !== undefined ? field.min : -Infinity;
        inputProps.max = field.max !== undefined ? field.max : Infinity;
        break;
      }

      case 'decimal': {
        inputProps.onChange = (value) => handleChange(field, value);
        inputProps.step = 0.01;
        inputProps.min = field.min !== undefined ? field.min : -Infinity;
        inputProps.max = field.max !== undefined ? field.max : Infinity;
        break;
      }

      default:
        break;
    }

    return inputProps;
  };

  const renderInput = (field, idx) => {
    const inputProps = {
      className: field.size,
      placeholder: field.placeholder,
      onChange: (e) => handleChange(field, e.target.value),
      value: fieldValues.value || field.value || field.default || 0,
      ...setAdditionalInputProps(field, idx),
    };

    if (isEditable) {
      switch (field.type) {
        case 'number':
        case 'decimal': {
          return <InputNumber {...inputProps} />;
        }

        case 'select': {
          return (
            <Select
              className={field.size}
              placeholder={field.placeholder}
              value={field.value}
              onChange={(value) => handleChange(field, value)}
            >
              {field.options && field.options.map((option, idx) => {
                const value = field.optionsValues && field.optionsValues.length ? field.optionsValues[idx] : option;

                return (
                  <Select.Option key={`select_option_${idx}`} value={value}>{option}</Select.Option>
                );
              })}
            </Select>
          );
        }

        case 'radio': {
          return (
            <Radio.Group defaultValue={field.value} onChange={(value) => handleChange(field, value.target.value)}>
              {field.options && field.options.map((option, idx) => (
                <Radio key={`radio_option_${idx}`} value={option}>{option}</Radio>
              ))}
            </Radio.Group>
          );
        }

        case 'date-picker': {
          const value = field.value ? {
            value: moment(field.value, DATE_FORMAT),
          } : {};

          return (
            <DatePicker
              format={DATE_FORMAT}
              onChange={(date, dateString) => handleChange(field, dateString)}
              disabledDate={(current) => disableDate(current, field.allowedDates)}
              {...value}
            />
          );
        }

        case 'checkbox-group': {
          const value = typeof field.value === 'string' ? field.value.split(',') : field.value;

          return (
            <Checkbox.Group value={value} onChange={(value) => handleChange(field, value)}>
              {field.options && field.options.map((option, idx) => (
                <Checkbox key={`checkbox_group_option_${idx}`} value={option}>{option}</Checkbox>
              ))}
            </Checkbox.Group>
          );
        }

        default:
          return <Input {...inputProps} />;
      }
    } else {
      return <div>{field.value || ''}</div>;
    }
  };

  return (
    <div className="form-row">
      {label && (
        <label>{label}</label>
      )}

      {fields.map((field, idx) => (
        <Fragment key={`${field.name}_${idx}`}>
          {fields.length > 1 && idx > 0 && (
          <span className="divider">-</span>
          )}

          {renderInput(field, idx)}

          {isDeletable && (
          <div className="delete-row">
            <Popconfirm
              title="Are you sure you want to delete this line?"
              onConfirm={handleDeleteField}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="clean" shape="circle" icon="delete" />
            </Popconfirm>
          </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default FormRow;
