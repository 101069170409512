import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";

import { fetchPlayerTransfers } from '../../store/actions/players';
import { DATE_FORMAT } from "@/constants/time";
// import { SpanTable } from '@/components';
import EditableTransfersTable from './components/EditableTransfersTable'

const mapTransfersIntoTable = transfers =>(
  transfers?.map(
    transfer => ({
      team: transfer.teams.in.name,
      from: transfer.from ? moment(transfer.from).format(DATE_FORMAT) : null,
      to: transfer.to ? moment(transfer.to).format(DATE_FORMAT) : null,
      type: transfer.type,
      uuid:  transfer.teams.out?.uuid || transfer.teams.in?.uuid,
    })
  )
)

const TransfersTable = ({ playerUuid }) => {
  const transfers = useSelector(({ players }) => players?.selectedTransfers);
  const dispatch = useDispatch();

  const mapedTransfers = useMemo(
    () => mapTransfersIntoTable(transfers) || [],
    [transfers]
  );

  useEffect(
    () => {
      if (playerUuid) {
        dispatch(fetchPlayerTransfers(playerUuid));
      }
    },
    [playerUuid]
  );

  return (
    <EditableTransfersTable
      data={mapedTransfers}
    />
  )
}

export default TransfersTable;