import { getEventPredictions } from '@/clients/DataProduct/fixtures/predictions';

import { api } from '../../utils/api';
import * as TYPES from '../actionTypes';

export const fetchPredictions = (filters) => async (dispatch) => {
  try {
    const { data } = await api.get('/predictions', filters);

    dispatch({ type: TYPES.PREDICTIONS_FETCHED, payload: data });
  } catch (error) {
    error.handleGlobally();
  }
};

export const fetchSeasonsPredictions = (season) => async (dispatch) => {
  try {
    const { data: predictions } = await getEventPredictions({ seasonUuid: season.id });

    dispatch({ type: TYPES.PREDICTIONS_SEASON_FETCHED, payload: predictions });
  } catch (error) {
    error.handleGlobally();
  }
};

export const clearSeasonsPredictions = () => (dispatch) => {
  dispatch({ type: TYPES.PREDICTIONS_SEASON_CLEARED });
};
