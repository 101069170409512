import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Input } from 'antd';
import queryString from 'query-string';

import { Attributes, Loading, TreeView } from '../../components';

const ClubAttributes = ({
  clubAttributes, clubs, handleClubSelect, location, editedFormsData, setEditedFormsData,
}) => {
  const [searchResult, setSearchResult] = useState([]);
  const [searchFieldText, setSearchFieldText] = useState('');

  const getClubFromUrl = useCallback(() => {
    const params = queryString.parse(location.search);
    return (params && params.searchClub) || null;
  }, [location.search]);

  useEffect(() => {
    const searchedClub = getClubFromUrl();
    if (searchedClub) {
      setSearchFieldText(searchedClub);
    }
  }, [getClubFromUrl]);

  useEffect(() => {
    let searchResults = clubs.list;
    const searchedClub = getClubFromUrl();

    if (searchedClub) {
      searchResults = clubs.list.filter((club) => club.name.includes(searchedClub));
    }
    setSearchResult(searchResults);
  }, [
    clubs.list,
    getClubFromUrl,
  ]);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    if (clubs && clubs.list) {
      if (!value) {
        setSearchResult(clubs.list);
      } else if (value.length > 2) {
        const result = clubs.list.filter((club) => club.name.toLowerCase().includes(value.toLowerCase()));
        setSearchResult(result);
      }
    }
    setSearchFieldText(value);
  }, [
    clubs,
  ]);

  return (
    <div className="club-attributes-section">
      <div className="club-tree-view">

        {clubs.list.length > 0 ? (
          <>
            <Input onChange={handleSearch} value={searchFieldText} placeholder="Search club" />
            <TreeView data={searchResult} handleSelect={handleClubSelect} />
          </>
        ) : (
          <Loading resources="clubs" />
        )}
      </div>
      {(clubs.selected.id && clubAttributes) ? (
        <Attributes
          data={clubAttributes}
          editData={editedFormsData}
          handleSave={setEditedFormsData}
        />
      ) : (
        <div>Please select club</div>
      )}
    </div>
  );
};

export default ClubAttributes;
