import * as TYPES from '../actionTypes';
import connector from '@/clients/DataProduct/connector';
import { omitBy, isEmpty } from 'lodash';
import { api } from '../../utils/api';
import { toast } from 'react-toastify';

export const fetchPlayers = params => async dispatch => {
 try {
   const { data } = await connector({
    url: '/players',
    method: 'GET',
    params: omitBy(params, isEmpty)
    });
    const playersData = data.map(({info, team}) => ({ ...info, team }))
    dispatch({ type: TYPES.PLAYERS_FETCHED, payload: playersData });
 } catch(e) {
  //  e.handleGlobally();
 }
};

export const clearPlayers = () => dispatch => {
 dispatch({ type: TYPES.PLAYERS_CLEAR });
};

export const selectPlayer = playerId => dispatch => {
 dispatch({ type: TYPES.PLAYERS_SELECT, payload: playerId });
};

export const addPlayer = payload => async dispatch => {
 try {
   const { status } = await api.post('/players', payload);
   if(status === 200) {
     dispatch({ type: TYPES.PLAYERS_ADDED, payload });
   }
 } catch(error) {
  //  error.handleGlobally();
 }
};


export const simpleAddPlayer = async payload => {
  try {
    const { data, status } = await api.post('/players', payload);
    if(status) {
      toast.success('Successfully added!');
    }

    return data;
  } catch(error) {
   //  error.handleGlobally();
  }
 };

export const transferPlayer = (playerUuid, payload) => async dispatch => {
 try {
   const { status } = await api.post(`transfer/${playerUuid}`, payload);
   if(status === 200) {
     dispatch({ type: TYPES.PLAYERS_UPDATED, payload });
   }
 } catch(error) {
  //  error.handleGlobally();
 }
};

export const simpleTransferPlayer = async(playerUuid, payload) => {
  try {
    const { data, status } = await api.post(`transfers/${playerUuid}`, payload);
    if(status) {
      toast.success('Successfully transfered!');
    }

    return data;
  } catch(error) {
   //  error.handleGlobally();
  }
 };

export const fetchPlayerTransfers = (playerUuid) => async dispatch => {
  try {
    const { data } = await connector({
     url: '/transfers',
     method: 'GET',
     params: { playerUuid }
     });
     const [playerData] = data
     dispatch({ type: TYPES.PLAYER_TRANSFERS, payload: [...playerData?.transfers ] || [] });
  } catch(e) {
   //  e.handleGlobally();
  }
 };


export const removePlayerTransfer = (params) => async dispatch => {
  const { playerUuid, ...rest } = params
  try {
    const { data, status } = await api.delete(`transfers/${playerUuid}`, { data: { ...rest } });
    
     if(status) {
      data && await dispatch(fetchPlayerTransfers(playerUuid));
      toast.success('Transfer removed!');
    }

     return data;
  } catch(e) {
    e.handleGlobally();
  }
 };

 export const editPlayerTransfer = async (params) => {
  const { playerUuid, ...rest } = params
  try {
    const { data, status } = await api.put(`transfers/${playerUuid}`, { ...rest });
    
     if(status) {
      toast.success('Transfer edited successfully!');
    }

     return data;
  } catch(e) {
    e.handleGlobally();
  }
 };