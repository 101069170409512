import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';

import FormColumns from './FormColumns';
import FormRow from './FormRow';

import './Form.scss';

const DEFAULT_NEW_FIELD = {
  // to decide - how to handle new labels
  label: <Input className="small" placeholder="Enter label" onChange={(e) => console.log(e.target.value)} />,
  fields: [{
    type: 'text',
    placeholder: 'Enter translation',
  }],
};

const Form = ({
  editData = {},
  handleFormChange = () => {},
  form = {},
}) => {
  const [formData, setFormData] = useState(form);

  useEffect(() => {
    setFormData(form);
  }, [form]);

  const handleChangeRowField = (updatedField, sectionName) => {
    const updatedForm = {
      ...editData,
      [sectionName]: {},
    };

    formData.sections.forEach((section) => {
      if (section.name === sectionName) {
        section.rows.forEach((row) => {
          row.fields.forEach((field) => {
            if (field.name.includes('[')) {
              const regExp = new RegExp(/\[(.*?)\]/gm);
              const matches = regExp.exec(field.name);
              const arrayIndex = matches[1];
              const keyName = field.name.replace(`[${arrayIndex}]`, '');

              if (!updatedForm[sectionName][keyName]) {
                updatedForm[sectionName][keyName] = [];
              }

              updatedForm[sectionName][keyName][arrayIndex] = field.value;
            } else {
              updatedForm[sectionName][field.name] = field.value;
            }
          });
        });
      }
    });

    handleFormChange(updatedForm);
  };

  const addRow = (section, sectionIdx) => {
    const updatedFormData = {
      ...formData,
      sections: formData.sections.map((section, idx) => {
        if (sectionIdx === idx) {
          section.rows = [
            ...section.rows,
            DEFAULT_NEW_FIELD,
          ];
        }
        return section;
      }),
    };

    setFormData({ ...updatedFormData });
  };

  const handleDeleteField = (section, sectionIdx, rowIdx) => {
    const updatedFormData = {
      ...formData,
      sections: formData.sections.map((section, sIdx) => {
        if (sectionIdx === sIdx) {
          section.rows = section.rows.filter((row, rIdx) => rIdx !== rowIdx);
        }
        return section;
      }),
    };

    setFormData({ ...updatedFormData });
  };

  return (
    <section className="form">
      {formData.title && (
        <h3>{formData.title}</h3>
      )}

      <div className="form-content">
        {formData.sections && formData.sections.map((section, sectionIdx) => {
          const hasSectionLabels = section.rows && section.rows.find((row) => row.label !== '');

          return (
            <div key={`section_${sectionIdx}`} className="form-section">
              <h4>
                {section.title}
                {section.editable && (
                  <Button
                    type="primary"
                    shape="circle"
                    icon="plus"
                    onClick={() => addRow(section, sectionIdx)}
                  />
                )}
              </h4>

              {section.columns && (
                <FormColumns columns={section.columns} hasSectionLabels={hasSectionLabels} />
              )}

              {section.rows && section.rows.map((row, rowIdx) => {
                const isRowEditable = typeof row.editable === 'boolean' ? row.editable : true;

                return (
                  <FormRow
                    key={`row_${rowIdx}`}
                    label={row.label}
                    fields={row.fields}
                    handleChangeRowField={(form) => handleChangeRowField(form, section.name)}
                    isDeletable={section.editable}
                    isEditable={isRowEditable}
                    handleDeleteField={() => handleDeleteField(section, sectionIdx, rowIdx)}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
};

Form.propTypes = {
  editData: PropTypes.object,
  form: PropTypes.object.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

export default Form;
