/* eslint-disable */
export const createSingleFormField = (label, field, editable) => {
  return {
    label,
    editable,
    fields: [{ ...field }],
  };
};


const recompose = (obj, parts) => {
  if(parts[1]) {
    parts.splice(0, 1);
    const newString = parts.join('.');
    return recomposeObjectFromString(obj, newString);
  }
  return obj;
};


const recomposeObjectFromString = (obj, string) => {
  const parts = string.split('.');

  const firstPart = parts[0];
  if(firstPart.includes('[')) {
    // array
    const regExp = new RegExp(/\[(.*?)\]/gm);
    let matches = null;
    while((matches = regExp.exec(firstPart)) !== null) {
      const arrayIndex = matches[1];
      const keyName = firstPart.replace(`[${arrayIndex}]`, '');

      return recompose(obj[keyName][arrayIndex], parts);
    }
  } else {
    // object
    return recompose(obj[firstPart], parts);
  }
};


export const replaceFormVariables = (formData, object) => {
  let replacedFormString = JSON.stringify(formData);

  const doubleBracketRegExp = new RegExp(/\{\{(.*?)\}\}/gm);
  let matches = null;

  while((matches = doubleBracketRegExp.exec(replacedFormString)) !== null) {
    const stringToReplace = `{{${matches[1]}}}`;

    const recomposedObject = recomposeObjectFromString(object, matches[1]);
    replacedFormString = replacedFormString.replace(stringToReplace, recomposedObject || '');
  }

  return JSON.parse(replacedFormString);
};
