import { AxiosResponse } from "axios";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from 'lodash';
import FP from 'lodash/fp';

import { Awaited } from "@/types/common";

export const returnAxiosResponseDataWithParams = <
  Fn extends (...args: unknown[]) => Promise<AxiosResponse>,
  ArgsObj = Parameters<Fn>[0],
  ReturnData = Awaited<ReturnType<Fn>>['data']
> (fn: Fn) => async (params: ArgsObj) => ({
  data: (await fn(params)).data as ReturnData,
  params
});

export const useBasicSlice = <Store, Reducers = Record<string, unknown>>(selector: string | string[], actions?: Reducers) => {
  const state = useSelector<unknown, Store>(FP.get(selector));
  const dispatch = useDispatch();
  
  const bindedActions = useMemo(
    () => L.mapValues(
      actions as unknown as Record<string, unknown>,
      (fn: (...args: unknown[]) => void) => L.flow(fn, dispatch)
    ),
    []
  ) as unknown as typeof actions;  
  
  return {
    ...state,
    ...bindedActions
  }
}