import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';

import { SaveBar } from '../../components';
import { fetchTournaments, fetchTournamentRanking, saveTournamentRanking } from '../../store/actions/tournaments';
import { fetchAreas, selectArea } from '../../store/actions/areas';
import TreeFilter from '../../components/Filters/TreeFilter';

import './TournamentsRanking.scss';

const TournamentsRanking = () => {
  const tournamentsRanking = useSelector(({ tournamentsRanking }) => tournamentsRanking);
  const filters = useSelector(({ filters }) => filters);
  const areas = useSelector(({ areas }) => areas.tree);
  const tournaments = useSelector(({ tournaments }) => tournaments.list);
  const { area, sport } = filters;
  const dispatch = useDispatch();

  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [areasSelectPlaceholder, setAreasSelectPlaceholder] = useState();
  const [tournamentsList, setTournamentsList] = useState([]);

  useEffect(() => {
    if (sport?.name && area) {
      dispatch(fetchTournaments(area.code, sport.name));
      dispatch(fetchTournamentRanking(area.code));
    }
  }, [
    dispatch,
    area,
    sport,
  ]);

  useEffect(() => {
    !areas.length && dispatch(fetchAreas());
  }, [
    dispatch,
    areas.length,
  ]);

  useEffect(() => {
    if (area && area.id) {
      dispatch(selectArea(area.id));
      setAreasSelectPlaceholder(area.name);
    }
  }, [
    area,
    dispatch,
  ]);

  useEffect(() => {
    const missingTournamentsInRanking = tournaments.filter((tournament) => {
      const foundTournament = !tournamentsRanking.find((trnm) => trnm.uuid === tournament.id);

      return foundTournament;
    });

    const list = [
      ...tournamentsRanking,
      ...missingTournamentsInRanking.map((t, idx) => ({
        uuid: t.id,
        abbrev: t.abbrev,
        name: t.name,
        order: tournamentsRanking.length + idx,
      })),
    ];

    setTournamentsList(list);
    setSaveButtonEnabled(true);
  }, [
    tournaments,
    tournamentsRanking,
  ]);

  const moveTournament = (tournament, oldIndex, newIndex) => {
    if (newIndex < 0 || newIndex === tournamentsList.length) {
      return;
    }

    const reorderedTournamentsList = [
      ...tournamentsList,
    ];

    reorderedTournamentsList.splice(newIndex, 0, reorderedTournamentsList.splice(oldIndex, 1)[0]);
    setTournamentsList(reorderedTournamentsList);
    setSaveButtonEnabled(true);
  };

  const onRankingSave = () => {
    const rankingToSave = [];
    tournamentsList.forEach((tournament, idx) => {
      tournament.order = idx + 1;

      if (tournament.name && tournament.abbrev) {
        rankingToSave.push(tournament);
      }
    });

    dispatch(saveTournamentRanking(area.code, rankingToSave));
    setSaveButtonEnabled(false);
  };

  return useMemo(() => (
    <div className="ranking-container">
      <div>
        <SaveBar
          onSave={onRankingSave}
          disabled={!saveButtonEnabled}
        />
        <div className="page-title">Tournament ranking</div>
      </div>

      <div className="ranking">
        <div className="filters">
          {areas && <TreeFilter name="area" treeData={areas} placeholder={areasSelectPlaceholder} />}
        </div>

        <div className="tournaments-list-container">
          {tournamentsList && tournamentsList.map((tournament, idx) => (tournament.name ? (
            <div key={tournament.uuid} className="tournaments-list-position">
              <span className="index">
                {(idx + 1)}
                .
              </span>
              <span>{tournament.name}</span>
              <div className="actions">
                <div className="up" onClick={() => moveTournament(tournament, idx, idx - 1)}>
                  <Icon type="up" />
                </div>
                <div className="down" onClick={() => moveTournament(tournament, idx, idx + 1)}>
                  <Icon type="down" />
                </div>
              </div>
            </div>
          ) : null))}
        </div>
      </div>
    </div>
  ), [
    tournamentsList,
    saveButtonEnabled,
  ]);
};

export default TournamentsRanking;
