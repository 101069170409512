import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { Attributes, Loading, SaveBar } from '../../components';
import { selectArea } from '../../store/actions/areas';
import { fetchClubs, selectClub, updateClub } from '../../store/actions/clubs';
import { DEFAULT_CLUB_ATTRIBUTES } from '../../constants/clubs';
import { createSingleFormField, replaceFormVariables } from '../../utils/forms';

import ClubAttributes from './ClubAttributes';
import { attributesFormData } from './Forms';

import './Configuration.scss';

const Configuration = ({ location }) => {
  const filters = useSelector(({ filters }) => filters);
  const areas = useSelector(({ areas }) => areas);
  const clubs = useSelector(({ clubs }) => clubs);
  const dispatch = useDispatch();

  const [clubAttributes, setClubAttributes] = useState({});
  const [areaAttributesFormData, setAreaAttributesFormData] = useState(null);
  const [editedFormsData, setEditedFormsData] = useState({});

  const setOriginalFormData = useCallback(() => {
    if (areas.selected) {
      const attributesForm = replaceFormVariables(attributesFormData, areas.selected);
      const uploaderData = ['id', 'badgeUrl', 'contextType'];
      const addUploaderData = (data) => data.map((attr) => {
        attributesForm[attr] = areas.selected[attr];
        return null;
      });

      addUploaderData(uploaderData);
      setAreaAttributesFormData(attributesForm);
    }
  }, [
    areas.selected,
  ]);

  useEffect(() => {
    if (filters.area && filters.area.id && areas.selected) {
      dispatch(fetchClubs({areaCode: filters.area.code}));
    }
  }, [
    dispatch,
    filters.area,
  ]);

  useEffect(() => {
    if (filters.area && filters.area.id) {
      if (areas.selected && areas.selected.name) {
        setOriginalFormData();
      } else if (areas.tree && areas.tree.length) {
        dispatch(selectArea(filters.area.id));
      }
    }
  }, [
    setOriginalFormData,
    filters.area,
    areas.selected,
    areas.tree,
    areas.tree.length,
  ]);

  useEffect(() => {
    if (clubs.selected && clubs.selected.id) {
      const attributes = {
        ...DEFAULT_CLUB_ATTRIBUTES,
      };

      attributes.sections[0].rows = [];
      attributes.sections[0].rows.push(
        createSingleFormField('Displayed name', {
          type: 'text', name: 'displayed_name', value: clubs.selected.displayedName || clubs.selected.shortName, size: 'big',
        }, true),
        createSingleFormField('Short Name', { type: 'text', name: 'short_name', value: clubs.selected.shortName }, false),
        createSingleFormField('Abbreviation', { type: 'text', name: 'abbreviation', value: clubs.selected.acronym }, false),
        createSingleFormField('Full Name', { type: 'text', name: 'name', value: clubs.selected.name }, false),
        createSingleFormField('Established', {
          type: 'text', name: 'established', value: clubs.selected.founded, size: 'small',
        }, false),
      );

      clubs.selected = {
        ...clubs.selected,
        ...attributes,
      };

      setClubAttributes(clubs.selected);
    }
  }, [
    clubs,
  ]);

  const handleClubSelect = (keys) => {
    if (keys && keys.length) {
      dispatch(selectClub({ name: keys[0] }));
    }
  };

  const onConfigurationSave = () => {
    dispatch(updateClub(clubs.selected, editedFormsData.attributes));
    setEditedFormsData({});
  };

  return useMemo(() => (filters.sport.uuid && filters.area && areas.selected ? (
    <section className="configuration-container">
      <SaveBar
        onSave={onConfigurationSave}
        disabled={!Object.entries(editedFormsData).length}
      />

      <div className="page-title">{areas.selected.name}</div>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Attributes" key="1">
          <div className="area-attributes-section">
            {areas.selected && areas.selected.id ? (
              <Attributes data={areaAttributesFormData} editData={areas.selected} />
            ) : (
              <Loading resources="attributes" />
            )}
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Clubs" key="2">
          {clubs?.list?.length ? (
            <ClubAttributes
              clubAttributes={clubAttributes}
              clubs={clubs}
              handleClubSelect={handleClubSelect}
              location={location}
              setEditedFormsData={setEditedFormsData}
              editedFormsData={editedFormsData}
            />
          ) : null}
        </Tabs.TabPane>

        {/* <Tabs.TabPane tab="National teams" key="3"> */}
        {/*  <div className="national-teams-section"> */}
        {/*    {(areas.selected && areas.selected.nationalTeams && Object.keys(areas.selected.nationalTeams).length) ? ( */}
        {/*      <SpanTable */}
        {/*        columnsNotToShow={['id']} */}
        {/*        data={areas.selected.nationalTeams} */}
        {/*      /> */}
        {/*    ) : null} */}
        {/*  </div> */}
        {/* </Tabs.TabPane> */}
      </Tabs>
    </section>
  ) : (
    <div>Please select sport and area</div>
  )), [
    filters.sport,
    filters.area,
    areas,
    areas.selected,
    clubs,
    clubs.selected,
    areaAttributesFormData,
    editedFormsData,
  ]);
};

export default Configuration;
