import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'lodash';
import FP from 'lodash/fp';

// Store
import { useSeasonsSlice } from '@/store/slices/seasons';
import { fetchTournamentFixtures } from '@/store/actions/tournaments';
import { setFilters } from '@/store/actions/filters';

// Clients
import { EventStatus } from '@/clients/DataProduct/fixtures/types';

// Common
import useFiltersStoreSelector, { ReduxArea, ReduxTournament } from '@/hooks/useFiltersStoreSelector';

// Internal
import { WidgetFixtureFilterData } from '@/views/Demo/Demo.types';
import { ActiveFixtureRow } from './useActiveDraft';

const useActiveFixtureSync = (
  activeFixtureFilters: Partial<WidgetFixtureFilterData>, 
  activeFixtureRow: ActiveFixtureRow
) => {
  // Redux Slices
  const { seasonsList } = useSeasonsSlice();

  // Redux selectors
  const areasList = useSelector(FP.get('areas.tree')) as ReduxArea[];
  const tournamentsList = useSelector(FP.get('tournaments.list')) as ReduxTournament[];
  const selectedTournament = useSelector(FP.get('tournaments.selected')) as ReduxTournament;
  const { area: selectedArea, season: selectedSeason } = useFiltersStoreSelector();
  const dispatch = useDispatch();

  // Set filters area based on active filter object
  useEffect(
    () => { 
      if (
        L.has(activeFixtureFilters, 'areaUUID') &&
        L.negate(L.eq)(activeFixtureFilters?.areaUUID, selectedArea?.id)
      ) {
        for (const continent of areasList) {
          const newArea = L.find(
            continent.areas,
            ['uuid', activeFixtureFilters?.areaUUID]
          );

          if (newArea) {
            const { uuid: id, name, areaCode: code } = newArea;
            dispatch(setFilters('area', { id, name, code }));
            break;
          }
        }
      }
     },
    [activeFixtureFilters, areasList]
  );

  // Set filters tournament/competition based on active filter object
  useEffect(
    () => { 
      if (
        L.has(activeFixtureFilters, 'competitionUUID') &&
        L.negate(L.eq)(activeFixtureFilters?.competitionUUID, selectedTournament?.id)
      ) {
        const newCompetition = L.find(
          tournamentsList,
          ['id', activeFixtureFilters?.competitionUUID]
        );

        if (newCompetition) {
          dispatch(setFilters('tournament', newCompetition));
        }
      }
     },
    [activeFixtureFilters, tournamentsList]
  );

  // Set filters season based on active filter object
  useEffect(
    () => { 
      if (
        L.has(activeFixtureFilters, 'seasonUUID') &&
        L.negate(L.eq)(activeFixtureFilters?.seasonUUID, selectedSeason?.id)
      ) {
        const newSeason = L.find(
          seasonsList,
          ['id', activeFixtureFilters?.seasonUUID]
        );

        if (newSeason) {
          dispatch(setFilters('season', newSeason));
        }
      }
    },
    [activeFixtureFilters, seasonsList]
  );

  // Should fetch tournaments fixtures based on correct filters or page init 
  useEffect(
    () => { 
      if (
        activeFixtureRow?.date && 
        selectedSeason?.id
      ) {
         dispatch(
          fetchTournamentFixtures({
            seasonUuid: selectedSeason?.id,
            eventStatus: EventStatus.SCHEDULED,
            limit: 40,
            from: activeFixtureRow?.date
          })
        );
      }
    },
    [
      activeFixtureFilters,
      selectedSeason
    ]
  );
}

export default useActiveFixtureSync;