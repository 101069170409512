export const MENU = [
  {
    name: 'Setup & Configuration',
    submenus: [{
      target: 'setup',
      name: 'Setup',
    }, {
      target: 'configuration',
      name: 'Configuration',
    }],
  },
  {
    name: 'Tournaments',
    submenus: [{
      target: 'tournaments',
      name: 'Tournaments',
    // }, {
    //   target: 'tournaments-ranking',
    //   name: 'Ranking',
    }],
  },
  {
    name: 'Prediction',
    submenus: [
      // {
      //   target: 'prediction',
      //   name: 'Prediction',
      // }, 
      {
        target: 'analytics',
        name: 'Analytics',
      }, 
      {
        target: 'demo',
        name: 'Landing page widget',
      }
    ],
  },
  {
    name: 'Players',
    submenus: [
      // {
      //   target: 'prediction',
      //   name: 'Prediction',
      // }, 
      {
        target: 'players',
        name: 'Manage transfers & data',
      }, 
    ],
  },

  // {
  //   menuItemTarget: 'import-matching',
  //   menuItemName: 'Import and matching',
  // },

  // {
  //   menuItemTarget: 'data-export',
  //   menuItemName: 'Data export',
  // },
];
