import React, { FC, useCallback } from 'react';
import L from 'lodash';
import FP from 'lodash/fp';

// Clients
import { WidgetMatchInfo, WidgetType } from '@/clients/CmsAPI/widgets/types';

// Store
import { useWidgetsSlice, WidgetsInfoRecords } from '@/store/slices/widgets';

// Components
import { SaveBar } from '@/components';

// Common
import { DateISOString, UUID } from '@/types/common';

interface HeaderProps {
  activeWidgetType: WidgetType, 
  activeWidgetsDraft: WidgetsInfoRecords, 
  shouldSaveActiveWidgetsDraft: boolean
}

const Header: FC<HeaderProps> = ({ activeWidgetType, activeWidgetsDraft, shouldSaveActiveWidgetsDraft }) => {
  const { updateWidgetsInfo } = useWidgetsSlice();

  const onActiveWidgetsSave = useCallback(
    () => {
      const compactIds = FP.update(1, L.compact)

      const removeEmptyIds = L.flow(
        FP.nth(1),
        L.negate(L.isEmpty)
      );

      const transformIntoMatchInfo = L.flow(
        FP.zip(['date', 'ids']),
        L.fromPairs
      ) as (dateAndIdsTuple: [DateISOString, UUID[]]) => WidgetMatchInfo

      updateWidgetsInfo({
        type: activeWidgetType,
        matches: L
          .chain(activeWidgetsDraft)
          .toPairs()
          .map(compactIds)
          .filter(removeEmptyIds)
          .map(transformIntoMatchInfo)
          .value()
      });
    },
    [
      activeWidgetType,
      activeWidgetsDraft
    ]
  );

  return (
    <div className="demo-header">
      <SaveBar
        onSave={onActiveWidgetsSave}
        disabled={!shouldSaveActiveWidgetsDraft}
      />
      <div className="page-title">DEMO user matches selection for Landing Page</div>
    </div>
  )
}

export default Header;