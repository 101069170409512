import React, { Fragment } from 'react';
import { Tabs } from 'antd';

import { Attributes } from '../../components';
import TransfersTable from './TransfersTable';
import TransferFormWrapper from './components/TransferFormWrapper'

const PlayerAttributes = ({  playerAttributes, editedFormsData, setEditedFormsData }) => {
  return (
    <Fragment>
    {playerAttributes ? (
      <Tabs defaultActiveKey="1" >
        <Tabs.TabPane tab="Player Information" key="1">
            <Attributes
              data={playerAttributes}
              editData={editedFormsData}
              handleSave={setEditedFormsData}
              withUploader={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Transfers table" key="2">
            <TransfersTable playerUuid={playerAttributes.uuid} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="New transfer form" key="3">
          <TransferFormWrapper playerUuid={playerAttributes.uuid} />
        </Tabs.TabPane>
      </Tabs>
      ) : (
        <div>Please select player</div>
      )}
    </Fragment>
  );
};

export default PlayerAttributes;