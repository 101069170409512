import { api } from '../../utils/api';
import { convertObjectKeysToUnderscore } from '../../utils/formatters';
import * as TYPES from '../actionTypes';
import { clearFilters } from './filters';

export const fetchPackages = (packageItem) => async (dispatch) => {
  try {
    const { data } = await api.get('/api/packages');

    dispatch({ type: TYPES.PACKAGES_FETCHED, payload: data.packages });
    packageItem && dispatch(selectPackage(packageItem._id));
  } catch (e) {
    e.handleGlobally();
  }
};

export const selectPackage = (packageId) => (dispatch) => {
  dispatch({ type: TYPES.PACKAGE_SELECTED, payload: packageId });
};

export const unselectPackage = () => (dispatch) => {
  dispatch({ type: TYPES.PACKAGE_UNSELECTED });
};

export const savePackage = (packageData) => (dispatch) => {
  if (packageData.id) {
    return dispatch(updatePackage(packageData));
  }

  return dispatch(addPackage(packageData));
};

export const addPackage = (packageData) => async (dispatch) => {
  try {
    const { status, data: { packageItem } } = await api.post('/api/package', {
      package: { ...convertObjectKeysToUnderscore(packageData) },
    });

    if (status === 200) {
      dispatch(fetchPackages(packageItem));
    }
  } catch (error) {
    error.handleGlobally();
  }
};

export const updatePackage = (packageData) => async (dispatch) => {
  try {
    const { status } = await api.put(`/api/package/${packageData.id}`, {
      package: { ...convertObjectKeysToUnderscore(packageData) },
    });

    if (status === 200) {
      await dispatch(fetchPackages());
      dispatch(selectPackage(packageData.id));
    }
  } catch (error) {
    error.handleGlobally();
  }
};

export const deletePackage = (packageId) => async (dispatch) => {
  try {
    const { status } = await api.delete(`/api/package/${packageId}`);

    if (status === 200) {
      dispatch(clearFilters('package'));
      dispatch(fetchPackages());
    }

    dispatch({ type: TYPES.PACKAGE_DELETED, payload: packageId });
    return { success: true };
  } catch (error) {
    error.handleGlobally();
    return { success: false };
  }
};
