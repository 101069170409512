import connector from '@/clients/DataProduct/connector';
import { UUID } from '@/types/common';

import { GetCompetitionsParams } from "./competitions";
import { RoundInfo, Season } from './types';

interface GetSeasonsParams extends GetCompetitionsParams {
  competitionUuid: UUID;
  searchPhrase: string;
}

const tempResponseConverter = (data: Season[]) => data.map(
  ({ uuid: id, name, year }) => ({ id, year, name })
);

export const getSeasons = (params: Partial<GetSeasonsParams>) => connector<ReturnType<typeof tempResponseConverter>>({
  url: '/environments/seasons',
  method: 'GET',
  params,
  transformResponse: tempResponseConverter
});

export const getSeasonRounds = (params: { seasonUuid: UUID }) => connector<RoundInfo[]>({
  url: '/environments/seasons/rounds',
  params
})