import React from 'react';

import football from '../../assets/images/football.png';

import './Loading.scss';

const Loading = ({ resources }) => (
  <div className="loading-container">
    <h4>
      Loading
      {` ${resources}`}
      ...
    </h4>
    <img src={football} alt="ball" />
    <div className="shadow" />
  </div>
);

export default Loading;
