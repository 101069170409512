import { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import L from 'lodash';
import FP from 'lodash/fp';

import { api } from "@/utils/api";
import { DeleteWidgetParams, PutWidgetsParams, WidgetInfo, WidgetType } from "./types";
import { formatIntoDateWithoutTimeISOString } from "@/utils/dates";

export const getWidgetsInfo = async ({ type }: { type: WidgetType }) => {
  const response = await api.get(`/widgets/${type}`);
  response.data = camelcaseKeys(response.data, { deep: true });

  return response as AxiosResponse<WidgetInfo[]>;
}

export const putWidgetsInfo = ({ type, matches }: PutWidgetsParams) => (
  api.put(
    `/widgets/${type}`, 
    { matches: L.map(matches, FP.update('date', formatIntoDateWithoutTimeISOString)) }
  ) as Promise<AxiosResponse>
);

export const deleteWidgetInfo = ({ type, date }: DeleteWidgetParams) => (
  api.deleteAPI(`/widgets/${type}`, { data: { date: formatIntoDateWithoutTimeISOString(date) } }) as Promise<AxiosResponse>
);

