const nameFormData = {
  title: 'Name',
  sections: [{
    title: '',
    name: 'name',
    rows: [{
      label: 'Name',
      fields: [{
        type: 'text',
        placeholder: 'Enter name',
        name: 'name',
        value: '{{name}}',
      }],
    }, {
      label: 'Key',
      fields: [{
        type: 'text',
        placeholder: 'Enter key',
        name: 'key',
        value: '{{key}}',
      }],
    }],
  }],
};

export default nameFormData;
