import L from "lodash";
import FP from "lodash/fp";
import moment from "moment";

import connector from "@/clients/DataProduct/connector";
import { EventStatus, Fixture } from "./types";
import { UUID, DateISOString } from "@/types/common";
import { DATE_FORMAT, TIME_FORMAT } from "@/constants/time";

export type GetFixtureParams = Partial<{
  seasonUuid: UUID | UUID[];
  eventUuid: UUID | UUID[];
  teamUuid: UUID;
  roundName: string;
  eventStatus: EventStatus | EventStatus[];
  from: DateISOString;
  to: DateISOString;
  before: number;
  after: number;
  first: number;
  last: number;
  limit: number;
  offset: number;
}>

export const tempResponseConverter = (data: Fixture[]) => data.map(
  fixture => {
    const { home, away, event } = fixture;
    const { uuid: id, result, status, startDate } = event;

    return {
      id,
      FT: result,
      status: L.startCase(status),
      date: moment(startDate).format(DATE_FORMAT),
      time: moment(startDate).format(TIME_FORMAT),
      home: home?.teamDetails?.displayedName || home?.name,
      away: away?.teamDetails?.displayedName || away?.name,
    }
  }
);

const joinArrayParams = <T extends Record<string, unknown>>(key: keyof T) => L.cond<T, T>([
  [
    L.flow(FP.get(key), L.isArray), 
    FP.update(key, FP.join(','))
  ],
  [L.stubTrue, L.identity]
]);

export const getFixtures = (params: GetFixtureParams) => {
  const convertedParams = L.flow(
    joinArrayParams<GetFixtureParams>('seasonUuid'),
    joinArrayParams('eventUuid'),
    joinArrayParams('eventStatus'),
  )(params);

  return connector<Fixture[]>({
    url: 'fixtures/events',
    params: convertedParams,
  });
}