export const SAVE_BAR_SHOW = 'SAVE_BAR_SHOW';
export const SAVE_BAR_HIDE = 'SAVE_BAR_HIDE';
export const SAVE_BAR_SET_RESET = 'SAVE_BAR_SET_RESET';

export const SET_FILTERS = 'SET_FILTERS';
export const SET_URL_FILTERS = 'SET_URL_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const SET_UP_FETCHED = 'SET_UP_FETCHED';

export const SPORTS_FETCHED = 'SPORTS_FETCHED';
export const SPORT_PREDICTION_FETCHED = 'SPORT_PREDICTION_FETCHED';

export const AREAS_FETCHED = 'AREAS_FETCHED';
export const AREAS_CLEAR = 'AREAS_CLEAR';
export const AREA_SELECT = 'AREA_SELECT';
export const AREA_FEDERATION_FETCHED = 'AREA_FEDERATION_FETCHED';
export const AREA_NATIONAL_TEAMS_FETCHED = 'AREA_NATIONAL_TEAMS_FETCHED';
export const AREA_TOURNAMENTS_FETCHED = 'AREA_TOURNAMENTS_FETCHED';

export const CLUBS_CLEANED = 'CLUBS_CLEANED';
export const CLUBS_FETCHED = 'CLUBS_FETCHED';
export const CLUB_SELECT = 'CLUB_SELECT';
export const CLUB_DISPLAYED_NAME_UPDATED = 'CLUB_DISPLAYED_NAME_UPDATED';

export const IMPORT_EXECUTED = 'IMPORT_EXECUTED';

export const PREDICTIONS_FETCHED = 'PREDICTIONS_FETCHED';
export const PREDICTIONS_SEASON_FETCHED = 'PREDICTIONS_SEASON_FETCHED';
export const PREDICTIONS_SEASON_CLEARED = 'PREDICTIONS_SEASON_CLEARED';

export const SEASONS_FETCHED = 'SEASONS_FETCHED';
export const SEASONS_CLEAR = 'SEASONS_CLEAR';
export const SEASONS_TEAMS_FETCHED = 'SEASONS_TEAMS_FETCHED';

export const DEMO_MATCHES_FETCHED = 'DEMO_MATCHES_FETCHED';
export const DEMO_MATCHES_SAVED = 'DEMO_MATCHES_SAVED';
export const DEMO_MATCHES_DELETED = 'DEMO_MATCHES_DELETED';
export const DEMO_ODDSEN_FETCHED = 'DEMO_ODDSEN_FETCHED';
export const DEMO_ODDSEN_SAVED = 'DEMO_ODDSEN_SAVED';
export const DEMO_ODDSEN_DELETED = 'DEMO_ODDSEN_DELETED';

export const TOURNAMENTS_FETCHED = 'TOURNAMENTS_FETCHED';
export const TOURNAMENTS_CLEAR = 'TOURNAMENTS_CLEAR';
export const TOURNAMENT_UPDATED = 'TOURNAMENT_UPDATED';
export const TOURNAMENT_SELECT = 'TOURNAMENT_SELECT';
export const TOURNAMENT_FIXTURES_FETCHED = 'TOURNAMENT_FIXTURES_FETCHED';
export const TOURNAMENT_RANKING_FETCHED = 'TOURNAMENT_RANKING_FETCHED';
export const TOURNAMENT_RANKING_CLEAR = 'TOURNAMENT_RANKING_CLEAR';
export const TOURNAMENT_SEASONS_FETCHED = 'TOURNAMENT_SEASONS_FETCHED';
export const TOURNAMENT_CONFIG_FETCHED = 'TOURNAMENT_CONFIG_FETCHED';
export const TOURNAMENT_FIXTURES_DATES_FETCHED = 'TOURNAMENT_FIXTURES_DATES_FETCHED';
export const TOURNAMENT_MATCH_DETAILS_FETCHED = 'TOURNAMENT_MATCH_DETAILS_FETCHED';
export const TOURNAMENT_STANDINGS_FETCHED = 'TOURNAMENT_STANDINGS_FETCHED';
export const TOURNAMENT_TEAMS_FETCHED = 'TOURNAMENT_TEAMS_FETCHED';
export const TOURNAMENT_TEAM_ENTRIES_FETCHED = 'TOURNAMENT_TEAM_ENTRIES_FETCHED';


export const IMAGE_COUNTRY_ADDED = 'IMAGE_COUNTRY_ADDED';
export const IMAGE_CLUB_ADDED = 'IMAGE_CLUB_ADDED';
export const IMAGE_TOURNAMENT_ADDED = 'IMAGE_TOURNAMENT_ADDED';
export const IMAGE_COUNTRY_DELETED = 'IMAGE_COUNTRY_DELETED';
export const IMAGE_CLUB_DELETED = 'IMAGE_CLUB_DELETED';
export const IMAGE_TOURNAMENT_DELETED = 'IMAGE_TOURNAMENT_DELETED';

export const PACKAGES_FETCHED = 'PACKAGES_FETCHED';
export const PACKAGE_SELECTED = 'PACKAGE_SELECTED';
export const PACKAGE_UNSELECTED = 'PACKAGE_UNSELECTED';
export const PACKAGE_DELETED = 'PACKAGE_DELETED';
export const IMAGE_PACKAGE_ADDED = 'IMAGE_PACKAGE_ADDED';
export const IMAGE_PACKAGE_DELETED = 'IMAGE_PACKAGE_DELETED';

export const PLAYERS_FETCHED = 'PLAYERS_FETCHED';
export const PLAYERS_CLEAR = 'PLAYERS_CLEAR';
export const PLAYERS_UPDATED = 'PLAYERS_UPDATED';
export const PLAYERS_ADDED = 'PLAYERS_ADDED';
export const PLAYERS_SELECT = 'PLAYERS_SELECT';

export const PLAYER_TRANSFERS = 'PLAYER_TRANSFERS';
