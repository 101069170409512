import React from 'react';

import { convertToDecimal } from '../../utils/formatters';

const AnalyticsGoals = ({ predictionsGoals }) => {
  const styleForZero = { opacity: 0.35, fontWeight: 300 };
  let totalPredictions = 0;
  let totalResults = 0;

  return (
    <ul className="analytics">
      <li className="list-header">
        <div className="row">
          <div className="percentage">Goals</div>
          <div className="count" title="Prediction">Prediction</div>
          <div className="count" title="% of Total">% of Total</div>
          <div className="count" title="Results">Results</div>
          <div className="count" title="% of Prediction">% of Prediction</div>
          <div className="count" title="Prediction / Total">P / T</div>
        </div>
      </li>
      {predictionsGoals.map((goals, idx) => {
        totalResults += goals.result;
        totalPredictions += goals.prediction;

        return (
          <li key={idx}>
            <div className="row">
              <div className="percentage">
                <span>{goals.start}</span>
                <span>{goals.end}</span>
              </div>
              <div className="count" style={goals.prediction === 0 ? styleForZero : {}}>{convertToDecimal(goals.prediction)}</div>
              <div className="count bold" title={goals.percentage_of_total} style={goals.percentage_of_total === 0 ? styleForZero : {}}>
                {convertToDecimal(goals.percentage_of_total, 2)}
                {' '}
                <span>%</span>
              </div>
              <div className="count" style={goals.result === 0 ? styleForZero : {}}>{convertToDecimal(goals.result, 0)}</div>
              <div className="count bold" title={goals.percentage_of_predictions} style={goals.percentage_of_predictions === 0 ? styleForZero : {}}>
                {convertToDecimal(goals.percentage_of_predictions, 2)}
                {' '}
                <span>%</span>
              </div>
              <div className="count bold" title={goals.percentage_of_predictions / goals.percentage_of_total} style={goals.percentage_of_predictions === 0 ? styleForZero : {}}>{convertToDecimal(goals.percentage_of_predictions / goals.percentage_of_total, 2)}</div>
            </div>
          </li>
        );
      })}
      <li className="list-header">
        <div className="row">
          <div className="percentage">Total</div>
          <div className="count">{convertToDecimal(totalPredictions)}</div>
          <div className="count" />
          <div className="count">{convertToDecimal(totalResults, 0)}</div>
          <div className="count" />
          <div className="count" />
        </div>
      </li>
    </ul>
  );
};

export default AnalyticsGoals;
