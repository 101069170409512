import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunkOptions } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { api } from './api';


const errorComposer = error => {
  return () => {
    const statusCode = error.response ? parseInt(error.response.status, 10) : null;
    const errorMessage = error.response && error.response.data
      && (error.response.data.error || error.response.data.message);

    switch(statusCode) {
      case 400:
        toast.error(errorMessage || 'Invalid email or password');
        break;

      case 401 || 403:
        break;

      case 422:
        toast.error(errorMessage);
        break;

      case 503:
        toast.error(errorMessage);
        break;

      default:
        toast.error('Something went wrong');
    }
  };
};

export function createAsyncThunkWithErrorComposer<Returned, ThunkArg, ThunkApiConfig = Record<string, unknown>>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>, 
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
) {
  return createAsyncThunk(
    typePrefix,
    async (arg: ThunkArg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (err) {
        errorComposer(err)
        return thunkAPI.rejectWithValue(err?.response?.data, err)
      }
    },
    options
  )
}

export default errorComposer;
