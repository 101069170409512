import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Icon, Popconfirm, Tabs,
} from 'antd';

import { deletePackage, savePackage, selectPackage } from '../../store/actions/packages';
import { replaceFormVariables } from '../../utils/forms';
import { attributesFormData } from './Forms';
import { Attributes, SaveBar } from '../../components';
import PackageDetails from './PackageDetails';

import './Packages.scss';

const Packages = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [attributesData, setAttributesData] = useState({});
  const [addingMode, setAddingMode] = useState(false);
  const [packageAttributesFormData, setPackageAttributesFormData] = useState(null);
  const [seasonsList, setSeasonsList] = useState([]);
  const [editedPackage, setEditedPackage] = useState({});

  const filters = useSelector(({ filters }) => filters);
  const packages = useSelector(({ packages }) => packages);
  const dispatch = useDispatch();

  const { selected: packageItem } = packages;

  const disableFields = (attributesForm) => {
    if (attributesForm && attributesForm.sections) {
      attributesForm.sections.forEach((section) => {
        section.rows.forEach((row) => {
          row.editable = false;
        });
      });
    }

    return attributesForm;
  };

  const setOriginalFormData = useCallback(() => {
    if (packageItem && packageItem.name) {
      setEditedPackage({ id: packageItem.id });

      let attributesForm = replaceFormVariables(attributesFormData, packageItem);
      if (packageItem.used) {
        attributesForm = disableFields(attributesForm);
      }

      const uploaderData = ['id', 'badgeUrl', 'contextType'];
      const addUploaderData = (data) => data.forEach((attr) => {
        attributesForm[attr] = packageItem[attr];
      });

      addUploaderData(uploaderData);
      setPackageAttributesFormData(attributesForm);
    }
  }, [
    packageItem,
  ]);

  useEffect(() => {
    if (filters.package && filters.package.id && !addingMode) {
      if (packageItem && packageItem.id) {
        setOriginalFormData();
      } else if (packages.list && packages.list.length) {
        dispatch(selectPackage(filters.package.id));
      }
    }
  }, [
    filters,
    setOriginalFormData,
    packageItem,
    packages,
    packages.list,
  ]);

  useEffect(() => {
    setSeasonsList((packageItem && packageItem.tournaments) || []);
    setAttributesData({});
    setAddingMode(false);
  }, [
    packageItem,
  ]);

  const handleAddPackage = () => {
    const attributesForm = replaceFormVariables(attributesFormData, {});
    setPackageAttributesFormData(attributesForm);

    setSeasonsList([]);
    setActiveKey(1);
    setAddingMode(true);
  };

  const handleCancelPackage = () => {
    setAddingMode(false);
    setAttributesData(attributesData);
    setSeasonsList(packageItem.tournaments);

    setOriginalFormData();
  };

  const onPackageSave = () => {
    let data = {};

    if (!addingMode) {
      data = {
        ...packageItem,
        ...attributesData.attributes,
        ...editedPackage,
      };
    } else {
      data = {
        ...attributesData.attributes,
      };
    }

    if (seasonsList.length) {
      data.tournaments = seasonsList;
    }

    dispatch(savePackage(data));
  };

  const handlePackageDelete = useCallback(async () => {
    const result = await dispatch(deletePackage(packageItem.id));
    result && result.success && setPackageAttributesFormData(null);
  }, [
    packageItem,
    dispatch,
    setPackageAttributesFormData,
  ]);

  return packageItem ? (
    <div>
      <SaveBar
        disabled={!Object.entries(attributesData).length && !seasonsList.length}
        onSave={onPackageSave}
      />

      <div className="packages-navbar">
        {!addingMode ? (
          <Button type="primary" onClick={handleAddPackage}>
            Add new package
          </Button>
        ) : (
          <Button type="secondary" onClick={handleCancelPackage}>
            Cancel adding package
          </Button>
        )}
      </div>

      {filters.package && !addingMode && (
        <div className="page-title package-title-navbar">
          {packageItem && packageItem.name}

          <Popconfirm
            title={`Are you sure to delete package ${packageItem && packageItem.name}?`}
            onConfirm={handlePackageDelete}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {!packageItem.used ? (
              <Icon type="delete" title="Delete the package" />
            ) : null}
          </Popconfirm>
        </div>
      )}

      {packageItem && packageItem.used && !addingMode ? (
        <h3 className="package-used">Package is being used. It cannot be edited nor deleted.</h3>
      ) : null}

      {(packages && packages.selected && packageAttributesFormData) && (
        <Tabs defaultActiveKey="1" activeKey={`${activeKey}`} onTabClick={setActiveKey}>
          <Tabs.TabPane tab="Basics" key="1">
            <div className="packages">
              <div className="packages-attributes-section">
                {packageAttributesFormData && (
                  <Attributes
                    data={packageAttributesFormData}
                    editData={editedPackage}
                    imageLabel="Logo"
                    handleSave={setAttributesData}
                    withUploader={!addingMode}
                    uploaderEnabled={!packageItem.used}
                  />
                )}
              </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Seasons" key="2" disabled={addingMode}>
            <PackageDetails
              seasonsList={seasonsList}
              setSeasonsList={setSeasonsList}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  ) : null;
};

export default Packages;
