import { useEffect, useRef } from 'react';

const useDidUpdateEffect = (func, deps) => {
  const didUpdate = useRef(false);

  useEffect(() => {
    didUpdate.current ? func() : didUpdate.current = true;
  }, deps);
};

export default useDidUpdateEffect;
