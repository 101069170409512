import { DateISOString, UUID } from "@/types/common"

export enum WidgetType {
  MATCH = 'match',
  ODDS = 'odds'
}

export interface WidgetInfo<TypeFor = WidgetType> {
  _id: string,
  typeFor: TypeFor,
  date: DateISOString,
  eventUuid: UUID,
  __v: number,
  createdAt: DateISOString,
  updatedAt: DateISOString
}

export interface WidgetMatchInfo {
  ids: UUID[];
  date: DateISOString
}

export interface PutWidgetsParams {
  type: WidgetType;
  matches: WidgetMatchInfo[];
}

export interface DeleteWidgetParams {
  type: WidgetType;
  date: DateISOString;
}