import connector from "@/clients/DataProduct/connector";
import { EventPredictions } from "@/clients/DataProduct/fixtures/types";
import { UUID } from "@/types/common";

export type GetPredictionsParams = Partial<{
  seasonUuid: UUID;
}>

export const getEventPredictions = (data: GetPredictionsParams) => connector<EventPredictions>({
  url: 'predictions/fixtures',
  method: 'POST',
  data,
})