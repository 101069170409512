import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import L from 'lodash';

// Store
import { useWidgetsSlice, WidgetsInfoRecords } from '@/store/slices/widgets';

// Clients
import { WidgetType } from '@/clients/CmsAPI/widgets/types';

// Common
import { DateISOString } from '@/types/common';

// Internal
import { syncDraftWithPayload } from '@/views/Demo/utils/draft';
import { WidgetsDraftHook } from './useWidgetsDraft';

type DraftHooks = { [x in WidgetType]: WidgetsDraftHook; };

type WidgetsActiveDraft = [
  [WidgetType, Dispatch<SetStateAction<WidgetType>>],
  [ActiveFixtureRow, Dispatch<SetStateAction<ActiveFixtureRow>>],
  [WidgetsInfoRecords, boolean, Dispatch<SetStateAction<WidgetsInfoRecords>>]
];

export interface ActiveFixtureRow {
  type: WidgetType,
  date: DateISOString,
  rowIdx: number;
}

const useActiveDraft = (drafts: DraftHooks) => {
  const { fetchWidgetsInfo, matchesWidgetsInfo, oddsenWidgetsInfo} = useWidgetsSlice();

  const [activeWidgetType, setActiveWidgetType] = useState(WidgetType.MATCH); 
  const [activeFixtureRow, setActiveFixtureRow] = useState({} as ActiveFixtureRow);

  const [
    [draft, setDraft],
    [shouldSave, setShouldSave],
    shouldFetch,
    initState
  ] = drafts[activeWidgetType];

  // Should fetch widget info of active type
  useEffect(
    () => { 
      if (shouldFetch.current) {
        shouldFetch.current = false;
        fetchWidgetsInfo({ type: activeWidgetType });
      }
    },
    [activeWidgetType]
  );

  // Draft sync
  useEffect(
    () => { syncActiveDraft(matchesWidgetsInfo) },
    [matchesWidgetsInfo]
  );

  useEffect(
    () => { syncActiveDraft(oddsenWidgetsInfo) },
    [oddsenWidgetsInfo]
  );

  const syncActiveDraft = useCallback(
    (state: WidgetsInfoRecords) => {
      setShouldSave(false);
      setDraft(
        syncDraftWithPayload(
          initState,
          state
        )
      );
    },
    [initState]
  )

  return [
    [activeWidgetType, setActiveWidgetType],
    [activeFixtureRow, setActiveFixtureRow],
    [
      draft, 
      shouldSave,
      L.flow(
        L.cloneDeep,
        setDraft,
        L.partial(setShouldSave, true)
      ) 
    ]
  ] as WidgetsActiveDraft
}

export default useActiveDraft;
