import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';

import { simpleFetchClubs } from '@/store/actions/clubs';
import { simpleFetchAreas } from '@/store/actions/areas'
import { fetchPlayerTransfers } from '@/store/actions/players';

import AreaSelect from './AreaSelect';

import NewPlayerTransformForm from '../FormsComponents/NewPlayerTransformForm'

import '@/components/Form/Form.scss';

const TransferFormWrapper = ({ playerUuid }) => {
  const dispatch = useDispatch();

  const [areas, setAreas] = useState('');
  const [areaCode, setAreaCode] = useState('');

  const [clubs, setClubs] = useState({});
  const [selectedClub, setSelectedClub] = useState('');

  const getClubsByArea = async() => {
    const clubs = await simpleFetchClubs({ areaCode });
    const sortedClubs = orderBy(clubs, 'displayedName')
    setClubs(sortedClubs)
  };

  const getAreas = async() => {
    const areas = await simpleFetchAreas();
    setAreas(areas)
  };

  useEffect(() => {
    getAreas()
  },
  [],
);

  useEffect(() => {
    if (areaCode) {
      getClubsByArea()
    }},
    [areaCode],
  );

  const dispatchPlayerTransfers = playerUuid => {
    dispatch(fetchPlayerTransfers(playerUuid));
  }

  return(
    <div style={{height: "800px", position: "relative", 'overflow-y': 'scroll'}}>
      {areas ? (
        <section className="form">
          <div className="form-selects-wrapper">
            <AreaSelect setAreaCode={setAreaCode} treeData={areas} />
            <div className="form-selects-wrapper-row">
              {clubs.length > 0 ? (
                <>
                  <label>Club</label>
                  <Select onChange={setSelectedClub} value={selectedClub}>
                    {clubs.map(
                      (club) => <Select.Option key={club.uuid} value={club.uuid}>{club.displayedName}</Select.Option>
                    )}
                  </Select>
                </>
              ) : (
                <div className="select-info-error">
                  You have to choose area to get club!
                </div>
              )}
            </div>
          </div>
          <div className="relative-form ">
            <NewPlayerTransformForm playerUuid={playerUuid} clubUuid={selectedClub} dispatchPlayerTransfers={dispatchPlayerTransfers} />
          </div>
        </section>
      ) : null}
    </div>
  )
}

export default TransferFormWrapper;