import React, { useEffect, useState } from 'react';

import Form from '../Form';
import UploaderSection from '../Uploader';

import './Attributes.scss';

const Attributes = ({
  children,
  data = {},
  editData = {},
  handleSave = () => {},
  imageLabel = '',
  withUploader = true,
  uploaderEnabled = true,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const updatedData = {
      ...data,
    };

    if (formData && formData.sections && formData.id === data.id) {
      updatedData.sections = [
        ...formData.sections,
      ];
    }

    setFormData(updatedData);
  }, [
    editData.id,
    data,
    setFormData,
  ]);

  return data ? (
    <section>
      {data.name && (<h1>{data.name}</h1>)}
      <div className="attributes">
        <div className="form-section">
          {formData && (
            <Form
              editData={editData}
              form={formData}
              handleFormChange={handleSave}
            />
          )}
        </div>
        {withUploader && (
          <div className="flag-uploader">
            <h4>{imageLabel || 'Flag'}</h4>
            <UploaderSection
              data={data}
              uploaderEnabled={uploaderEnabled}
            />
          </div>
        )}
      </div>

      {children}

    </section>
  ) : null;
};

export default Attributes;
