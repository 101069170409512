import React, { Fragment, useState, useCallback } from 'react';
import { Form, Input, Button, Select, DatePicker } from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { removePlayerTransfer, editPlayerTransfer, fetchPlayerTransfers } from '@/store/actions/players';

import './styles.scss'

class FormTableRow extends React.Component{
  handleSubmit = async e => {
    e.preventDefault();
    const {rowData, playerUuid, dispatchPlayerTransfers} = this.props

    const transfer = Object.fromEntries(rowData)

    const fromDate = this.props.form.getFieldValue('from')
    const toDate = this.props.form.getFieldValue('to')
    const type = this.props.form.getFieldValue('type');

    const memberToDateObj = toDate
      ? { memberTo: moment(toDate).format('YYYY-MM-DD') }
      : transfer.to
        ? { memberTo: moment(transfer.to).format('YYYY-MM-DD') }
        : {};
    const memberFromDateObj = toDate
      ? { memberFrom: moment(fromDate).format('YYYY-MM-DD') }
      : transfer.to
        ? { memberFrom: moment(transfer.from).format('YYYY-MM-DD') }
        : {};

    await editPlayerTransfer({
      playerUuid,
      teamUuid: transfer.uuid,
      ...memberFromDateObj,
      ...memberToDateObj,
      type: type || transfer.type,
    })

    await dispatchPlayerTransfers(playerUuid);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {rowData} = this.props;
    const [team, from, to, type] = rowData;
    const fromDateInitial = from[1] ? {initialValue: moment(from[1])} : {};
    const toDateInitial = to[1] ? { initialValue: moment(to[1]) } : {};

    return (
      <td colspan="5">
        <Form layout="inline" onSubmit={this.handleSubmit}>
          <div class="form-group">
            <Form.Item name="team">
              {getFieldDecorator('team', {
                rules: [{ required: false }],
                initialValue: team[1],
              })(
                <Input disabled size="small" />
              )}
            </Form.Item>

            <Form.Item name="from">
              {getFieldDecorator('from', {
                rules: [{ required: false }],
                ...fromDateInitial,
              })(
                <DatePicker />
              )}
            </Form.Item>

            <Form.Item name="to">
              {getFieldDecorator('to', {
                rules: [{ required: false }],
                ...toDateInitial,
              })(
                <DatePicker />
              )}
            </Form.Item>

            <Form.Item  name="type">
              {getFieldDecorator('type', {
                rules: [{ required: false }],
                initialValue: type[1],
              })(
                <Select>
                  <Select.Option value="N/A">n/a</Select.Option>
                  <Select.Option value="loan">loan</Select.Option>
                  <Select.Option value="endLoan">end of Loan</Select.Option>
                  <Select.Option value="free">free</Select.Option>
                  <Select.Option value="regular">regular</Select.Option>
                </Select>
              )}
            </Form.Item>
    
            <Form.Item>
              <Button
                icon="save"
                style={{
                  color: 'green',
                  borderColor: 'green'
                }}
                htmlType="submit"
              />
            </Form.Item>
          </div>
        </Form>
      </td>
    )
  }
}

const EditableTableRow = Form.create({ name: '' })(FormTableRow);

const SimpleDataTableRow = ({ rowData }) => (
  rowData?.map(
    cell => {
      const [key, value] = cell;
      return key !== 'uuid' && (
        <td>{value}</td>
      )
    }
  )
)


const TableRow = ({ rowData }) => {
  const [isEditable, setAsEditable] = useState(false);
  const player = useSelector(({ players }) => players?.selected);
  const dispatch = useDispatch();

  const dispatchPlayerTransfers = playerUuid => {
    dispatch(fetchPlayerTransfers(playerUuid));
  }


  const handlePlayerTransferRemove = useCallback(
    () => {
      const transfer = Object.fromEntries(rowData)
      const dateFromObj = transfer.from ? { memberFrom: moment(transfer.from).format('YYYY-MM-DD')} : {};
      const dateToObj = transfer.to ? { memberTo: moment(transfer.to).format('YYYY-MM-DD')} : {};

      dispatch(removePlayerTransfer({
        playerUuid: player.uuid,
        ...dateFromObj,
        ...dateToObj,
        teamUuid: transfer.uuid,
      }))
    },
    [rowData]
  )

  
  return (
    <tr>
      {
        isEditable
          ? (
            <EditableTableRow
              rowData={rowData}
              isEditable={isEditable}
              setAsEditable={setAsEditable}
              dispatchPlayerTransfers={dispatchPlayerTransfers}
              playerUuid={player.uuid}
            />
          ) : (
            <Fragment>
              <SimpleDataTableRow rowData={rowData} />
              <td></td>
            </Fragment>
          )
      }
      <td>
        <Button
          icon={isEditable ? "stop" : "edit"}
          style={{
            color: isEditable ? "orange" : "blue",
            borderColor: isEditable ? "orange" : "blue"
          }}
          onClick={() => setAsEditable(!isEditable)}
        />
      </td>
      <td>
        <Button
          icon="delete"
          style={{
            color: 'red',
            borderColor: 'red'
          }}
          onClick={handlePlayerTransferRemove}
        />
      </td>
    </tr>
  )
}



const EditableTransfersTable = ({ data }) => {
  return (
    <table className="table">
      <tbody>
        <tr>
          <th width="25%">team</th>
          <th width="20%">from date</th>
          <th width="20%">to date</th>
          <th width="15%">type</th>
          <th width="5%">save</th>
          <th width="10%">edit</th>
          <th width="5%">delete</th>
        </tr>
        {data?.map(
          rowData => (
            <TableRow
              key={`${rowData.team}_${rowData.from}_${rowData.to}`}
              rowData={Object.entries(rowData)}
            />
          )
        )}
      </tbody>
    </table>
  )
}

export default EditableTransfersTable;