import React from 'react';

import { convertToDecimal } from '../../utils/formatters';

const AnalyticsSeason = ({ predictionsSeason }) => {
  const styleForZero = { opacity: 0.35, fontWeight: 300 };
  let totalPredictions = 0;
  let totalResults = 0;

  return (
    <ul className="analytics">
      <li className="list-header">
        <div className="row">
          <div className="percentage">Percentage</div>
          <div className="count" title="Prediction">Prediction</div>
          <div className="count" title="% of Total">% of Total</div>
          <div className="count" title="Results">Results</div>
          <div className="count" title="% of Prediction">% of Prediction</div>
        </div>
      </li>
      {predictionsSeason.map((season, idx) => {
        totalResults += season.result;
        totalPredictions += season.prediction;

        return (
          <li key={idx}>
            <div className="row">
              <div className="percentage">
                <span>
                  {season.start}
                  %
                </span>
                <span>
                  {season.end}
                  %
                </span>
              </div>
              <div className="count" style={season.prediction === 0 ? styleForZero : {}}>{season.prediction}</div>
              <div className="count bold" style={season.percentage_of_total === 0 ? styleForZero : {}}>
                {convertToDecimal(season.percentage_of_total, 2)}
                {' '}
                <span>%</span>
              </div>
              <div className="count" style={season.result === 0 ? styleForZero : {}}>{season.result}</div>
              <div className="count bold" style={season.percentage_of_predictions === 0 ? styleForZero : {}}>
                {convertToDecimal(season.percentage_of_predictions, 2)}
                {' '}
                <span>%</span>
              </div>
            </div>
          </li>
        );
      })}
      <li className="list-header">
        <div className="row">
          <div className="percentage">Total</div>
          <div className="count">{totalPredictions}</div>
          <div className="count" />
          <div className="count">{totalResults}</div>
          <div className="count" />
        </div>
      </li>
    </ul>
  );
};

export default AnalyticsSeason;
