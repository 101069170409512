import connector from '@/clients/DataProduct/connector';
import { AreaBaseWithSubAreas } from './types';

const tempResponseConverter = (data: AreaBaseWithSubAreas[]) => {
  data.forEach(parentArea => {
    parentArea.areas = [
      {
        uuid: parentArea.uuid,
        name: parentArea.name,
        areaCode: parentArea.areaCode,
        countCompetitions: 0,
      },
      ...parentArea.areas,
    ]
  });

  return data?.map(area => ({
    ...area,
    code: area.areaCode,
    id: area.uuid,
    contextType: 'area',
    children: area.areas.map(subArea => ({
      ...subArea,
      code: subArea.areaCode,
      id: subArea.uuid,
      contextType: 'area',
    })),
  }))
};

export const getAreas = () => connector<ReturnType<typeof tempResponseConverter>>({
  url: '/environments/areas',
  method: 'GET',
  transformResponse: tempResponseConverter,
});
