import * as TYPES from '../actionTypes';

const INITIAL_STATE = {
  list: [],
  selected: {},
};

const clubsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.CLUBS_CLEANED:
      return {
        list: [],
        selected: {},
      };

    case TYPES.CLUBS_FETCHED:
      return {
        list: [
          ...action.payload,
        ],
        selected: {},
      };

    case TYPES.CLUB_SELECT:
      return {
        ...state,
        selected: state.list.find((club) => club.name === action.payload.name),
      };

    case TYPES.IMAGE_CLUB_ADDED:
      state.selected.badgeUrl = action.payload;
      state.list.forEach((club) => {
        if (club.id === state.selected.id) {
          club.badgeUrl = action.payload;
        }
      });

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    case TYPES.CLUB_DISPLAYED_NAME_UPDATED:
      state.selected.displayedName = action.payload;
      state.list.forEach((club) => {
        if (club.id === state.selected.id) {
          club.displayedName = action.payload;
        }
      });

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    case TYPES.IMAGE_CLUB_DELETED:
      delete state.selected.badgeUrl;
      state.list.forEach((club) => {
        if (club.id === state.selected.id) {
          delete club.badgeUrl;
        }
      });

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    default:
      return state;
  }
};

export default clubsReducer;
