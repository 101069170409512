import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPlayers } from '@/store/actions/players';

import ClubSelect from './ClubSelect';
import PlayersListWithSearchBar from './PlayersListWithSearchBar';

const PlayersListSection = ({ children, location }) => {
  const dispatch = useDispatch();
  
  const filters = useSelector(({ filters }) => filters);
  const { season } = filters;

  const [ club, setClub ] = useState('');

  useEffect(
    () => {
      if(season && season.id) {
        dispatch(fetchPlayers({
          seasonUuid: filters.season.id,
          teamUuid: club,
        }));
      }
    },
    [season, club]
  );

  return(
    <div className="players-container">
    <div className="player-attributes-section">
      <div className="player-tree-view">
        <ClubSelect club={club} setClub={setClub} />
        <PlayersListWithSearchBar location={location} />
      </div>
      <div className="player-tabs-section">
        {children}
      </div>
    </div>
    </div>
  )
}

export default PlayersListSection;

