import React from 'react';
import { 
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
 } from 'antd';
import moment from 'moment';

import { simpleAddPlayer } from '../../../store/actions/players';

import '@/components/Form/Form.scss';

 const basicStringDataInputs = [
   {label: 'Name', key: 'name'}, 
   {label: 'Short name', key: 'short_name'}, 
   {label: 'First name', key: 'first_name'}, 
   {label: 'Last name', key: 'last_name'}, 
   {label: 'Nickname', key: 'nickname'}, 
 ]

 const basicNumberDataInputs = [
  {label: 'Shirt number', key: 'shirt_nr'}, 
  {label: 'Weight', key: 'weight'}, 
  {label: 'Height', key: 'height'}, 
 ]


 const getFieldsKeys = fields => fields.map(field => field.key)

 class DataForm extends React.Component {

  handleSubmit = e => {
    e.preventDefault();

    const fieldsToGetValues = [
      ...getFieldsKeys(basicStringDataInputs),
      ...getFieldsKeys(basicNumberDataInputs),
      'gender',
      'position',
      'birthdate',
    ]

    const birthdate = this.props.form.getFieldValue('birthdate')
    const values = this.props.form.getFieldsValue(fieldsToGetValues);
    const requestValues = {
      ...values,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
    }
    simpleAddPlayer(requestValues);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <section className="form">
        <div className="form-content">
          <div className="relative-form">
            <Form
              onSubmit={this.handleSubmit}
            >
              <Form.Item className="label-colored-row" label="gender" name="gender">
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: 'Please select your gender!' }],
                })(
                  <Select>
                    <Select.Option value="male">male</Select.Option>
                    <Select.Option value="female">female</Select.Option>
                  </Select>
                )}
              </Form.Item>
              {basicStringDataInputs.map(
                field => (
                  <Form.Item className="label-colored-row" label={field.label} key={field.key}>
                    {getFieldDecorator(field.key, {
                      rules: [{ required: true, message: 'Field is required!' }],
                    })(
                      <Input type="primary"/>
                    )}
                  </Form.Item>
                )
              )}
              <Form.Item className="label-colored-row" label="position" name="position">
                {getFieldDecorator('position', {
                  rules: [{ required: true, message: 'Please select your gender!' }],
                })(
                  <Select>
                    <Select.Option value="goalkeeper">goalkeeper</Select.Option>
                    <Select.Option value="defender">defender</Select.Option>
                    <Select.Option value="midfielder">midfielder</Select.Option>
                    <Select.Option value="attacker">attacker</Select.Option>
                    <Select.Option value="unknown">unknown</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item className="label-colored-row"label="birthdate" name="birthdate">
                {getFieldDecorator('birthdate', {
                  rules: [{ required: true, message: 'Field is required!' }],
                })(
                  <DatePicker />
                )}
              </Form.Item>
              {basicNumberDataInputs.map(
                field => (
                  <Form.Item className="label-colored-row" label={field.label} key={field.key}>
                    {getFieldDecorator(field.key, {
                      rules: [{ required: true, message: 'Field is required!' }],
                    })(
                      <InputNumber />
                    )}
                  </Form.Item>
                )
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save player data
                </Button>
              </Form.Item>
            </Form>
            </div>
        </div>
      </section>
    );
  }
}

const NewPlayerForm = Form.create({ name: 'add_new_player' })(DataForm);
export default NewPlayerForm;