import * as TYPES from '../actionTypes';

const INITIAL_STATE = {
  list: [],
  selected: {},
};

const packagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PACKAGES_FETCHED:
      return {
        ...state,
        list: action.payload,
      };

    case TYPES.PACKAGE_SELECTED:
      return {
        ...state,
        selected: state.list.find((packageItem) => packageItem.id === action.payload),
      };

    case TYPES.PACKAGE_UNSELECTED:
      return {
        ...state,
        selected: {},
      };

    case TYPES.PACKAGE_DELETED:
      return {
        ...state,
        list: state.list.filter((packageItem) => packageItem.id !== action.payload),
        selected: {},
      };

    case TYPES.IMAGE_PACKAGE_ADDED:
      return {
        ...state,
        selected: {
          ...state.selected,
          badgeUrl: action.payload,
        },
      };

    case TYPES.IMAGE_PACKAGE_DELETED:
      delete state.selected.badgeUrl;

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    default:
      return state;
  }
};

export default packagesReducer;
