import React from 'react';

import './Form.scss';

const FormColumns = ({ columns, hasSectionLabels }) => (
  <div className="form-row">
    {hasSectionLabels && <label />}

    {columns.map((column, idx) => (
      <div
        key={`${column.label}_${idx}`}
        className="form-section-column"
        style={{ width: column.width }}
      >
        {column.label}
      </div>
    ))}
  </div>
);

export default FormColumns;
