import { useRef, useState, Dispatch, SetStateAction, MutableRefObject } from 'react';

// Store
import { WidgetsInfoRecords } from '@/store/slices/widgets';

// Internal
import { initRecordsDraft } from '@/views/Demo/utils/draft';

export type WidgetsDraftHook = [
  [WidgetsInfoRecords, Dispatch<SetStateAction<WidgetsInfoRecords>>],
  [boolean, Dispatch<SetStateAction<boolean>>],
  MutableRefObject<boolean>,
  WidgetsInfoRecords
];

const useWidgetsDraft = (numOfRows: number) => {
  const draftWidgetsInitState = initRecordsDraft(numOfRows);
  const shouldFetchWidgets = useRef(true);
  const [widgetsInfoDraft, setWidgetsInfoDraft] = useState<WidgetsInfoRecords>(draftWidgetsInitState);
  const [shouldSaveWidgets, setSaveWidgetsFlag] = useState(false); 

  return [
    [widgetsInfoDraft, setWidgetsInfoDraft],
    [shouldSaveWidgets, setSaveWidgetsFlag],
    shouldFetchWidgets,
    draftWidgetsInitState
  ] as WidgetsDraftHook;
}

export default useWidgetsDraft;