import axios from 'axios';

import errorComposer from './errorComposer';

// const STORAGE_AUTH_TOKEN_KEY = 'auth-token';
// const STORAGE_USER_KEY = 'user';
// const STORAGE_FILTERS = 'filters';

// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.REACT_APP_CMS_API_BASE_URL || '';
// axios.interceptors.response.use(undefined, error => {
//   error.handleGlobally = errorComposer(error);
//   return Promise.reject(error);
// });
class API {
  constructor() {
    API.instance = axios.create({ baseURL: window.__RUNTIME_CONFIG__.CMS_API_BASE_URL || '' });
    API.instance.interceptors.response.use(undefined, (error) => {
      error.handleGlobally = errorComposer(error);

      return Promise.reject(error);
    });
    API.instance.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      },
    );

    return this;
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    return this.tokenGenerator({ scope: 'write:all', audience: window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE_ADMIN_PANEL });
  }

  post = (url, params = {}, config = {}) => API.instance.post(url, params, config)

  put = (url, params = {}, config = {}) => API.instance.put(url, params, config)

  patch = (url, params = {}, config = {}) => API.instance.patch(url, params, config)

  get = (url, params = {}, config = {}) => {
    try {
      config.params = params;
      return API.instance.get(url, config);
    } catch ({ response }) {
      const status = parseInt(response.request.status, 10);
      if (status === 401) {
        this.logout();
      }
    }
  }

  delete = (url, config = {}) => API.instance.delete(url, config)

  deleteAPI = (url, config = {}) => API.instance.delete(url, config)
}

export const api = new API();
