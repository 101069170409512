import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { history } from './store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = (appState) => {
  history.push((appState && appState.returnTo) || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={window.__RUNTIME_CONFIG__.AUTH0_ISSUER_URL}
    clientId={window.__RUNTIME_CONFIG__.AUTH0_CLIENT_ID_ADMIN_PANEL}
    redirectUri={window.location.origin}
    audience={window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE_ADMIN_PANEL}
    scope="write:all"
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
