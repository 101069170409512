import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'antd';

import { Attributes, Loading, SaveBar } from '../../components';
import {
  saveTournamentData,
  selectTournament,
} from '../../store/actions/tournaments';
import { setResetFields } from '../../store/actions/saveBar';
import { replaceFormVariables } from '../../utils/forms';
import { attributesFormData } from './Forms';

import './Tournaments.scss';

const Tournaments = () => {
  const { area, season, tournament } = useSelector(({ filters }) => filters);
  const tournaments = useSelector(({ tournaments }) => tournaments);

  const dispatch = useDispatch();
  const [teamEntries, setTeamEntries] = useState();
  const [attributesData, setAttributesData] = useState(null);
  const [editedTournament, setEditedTournament] = useState({});
  const [tournamentAttributesFormData, setTournamentAttributesFormData] = useState(null);

  const setOriginalFormData = useCallback(() => {
    if (tournaments.selected && tournaments.selected.id) {
      setEditedTournament({ ...tournaments.selected });

      const attributesForm = replaceFormVariables(attributesFormData, tournaments.selected);
      const uploadAttributes = ['id', 'badgeUrl', 'contextType'];
      const addAttributesForUpload = (attributes) => {
        attributes.map((attr) => {
          attributesForm[attr] = tournaments.selected[attr];
          return null;
        });
      };

      addAttributesForUpload(uploadAttributes);
      setTournamentAttributesFormData(attributesForm);
    }
  }, [
    tournaments.selected,
  ]);

  useEffect(() => {
    if (tournaments.selected && tournaments.selected.id) {
      setOriginalFormData();
    } else if (tournaments.list.length && tournament && tournament.id) {
      dispatch(selectTournament(tournament.id));
    }
  }, [
    setOriginalFormData,
    tournament,
    tournaments,
    tournaments.list,
    tournaments.selected,
  ]);

  useEffect(() => {
    setAttributesData(null);
  }, [
    season,
  ]);

  useEffect(() => {
    if (tournaments.selected.teamEntries) {
      setTeamEntries({ ...tournaments.selected.teamEntries });
    }
  }, [
    tournaments.selected.teamEntries,
  ]);

  useEffect(() => {
    dispatch(setResetFields(setOriginalFormData));
  }, [
    dispatch,
    setOriginalFormData,
    tournaments,
  ]);

  const onTournamentSave = () => {
    if (attributesData && attributesData.id && attributesData.predictions) {
      const { id, predictions: { league_level: leagueLevel, prediction_level: predictionLevel, prediction_importance: predictionImportance } } = attributesData;
      dispatch(saveTournamentData({
        id, leagueLevel, predictionLevel, predictionImportance,
      }));
      setAttributesData(null);
    }
  };

  return useMemo(() => (area ? (
    <section className="tournaments-container">
      <div className="tournaments-header">
        <SaveBar
          onSave={onTournamentSave}
          disabled={!attributesData}
        />
        <div className="page-title">
          {tournaments.selected.name}
          {' '}
          {season && season.year}
        </div>
      </div>

      {season && (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Attributes" key="1">
          {(tournaments.selected && editedTournament) ? (
            <div className="tournaments-attributes-section">
              <Attributes
                data={tournamentAttributesFormData}
                editData={editedTournament}
                handleSave={setAttributesData}
              />
            </div>
          ) : <Loading resources="attributes" />}
        </Tabs.TabPane>
      </Tabs>
      )}
    </section>
  ) : <div>Please, select area, season and tournament</div>), [
    area,
    attributesData,
    editedTournament,
    season,
    teamEntries,
    tournamentAttributesFormData,
    tournaments.selected,
    tournaments.selected.id,
  ]);
};

export default Tournaments;
