import * as TYPES from '../actionTypes';
import { getAreas } from '../../clients/DataProduct/environments/areas';

import { api } from '../../utils/api';

export const fetchAreas = () => async (dispatch) => {
  try {
    const { data: payload } = await getAreas();
    dispatch({ type: TYPES.AREAS_FETCHED, payload });
  } catch (error) {
    // error.handleGlobally();
  }
};

export const simpleFetchAreas = async () => {
  const { data: payload } = await getAreas();

  return payload;
};

export const clearAreas = () => (dispatch) => {
  dispatch({ type: TYPES.AREAS_CLEAR });
};

export const selectArea = (areaId) => (dispatch) => {
  dispatch({ type: TYPES.AREA_SELECT, payload: areaId });
};


export const fetchAreaFederation = area => async dispatch => {
  try {
    const { data } = await api.get(`/area/${area.id}/federation`);
    dispatch({ type: TYPES.AREA_FEDERATION_FETCHED, payload: {
      areaId: area.id,
      federation: data.federation,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchNationalTeams = area => async dispatch => {
  try {
    const { data } = await api.get(`/area/${area.id}/national_teams`);
    dispatch({ type: TYPES.AREA_NATIONAL_TEAMS_FETCHED, payload: {
      areaId: area.id,
      nationalTeams: data.national_teams,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchAreaTournaments = area => async dispatch => {
  try {
    const { data } = await api.get(`/area/${area.id}/tournaments`);
    dispatch({ type: TYPES.AREA_TOURNAMENTS_FETCHED, payload: {
      areaId: area.id,
      tournaments: data.tournaments,
    } });
  } catch(e) {
    e.handleGlobally();
  }
};