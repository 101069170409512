import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';

import TreeView from '@/components/TreeView/TreeView';
import FilterHeader from '@/components/Filters/FilterHeader';

import '@/components/Form/Form.scss';

const AreaSelect = ({ treeData = [], setAreaCode }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [treeVisibility, setTreeVisibility] = useState(false);
  const [treeViewData, setTreeViewData] = useState(treeData);

  useEffect(() => {
    setTreeViewData(treeData);
  }, [treeData]);

  const setSelectedItem = (item) => ({ id: item.id, name: item.name, code: item.code || null });

  const getSelectedValue = (key) => {
    let selected = {};
    treeData.forEach((item) => {
      if (item.name.toLowerCase() === key.toLowerCase()) {
        selected = setSelectedItem(item);
      }

      if (item.children) {
        item.children.forEach((child) => {
          if (child.name.toLowerCase() === key.toLowerCase()) {
            selected = setSelectedItem(child);
          }
        });
      }
    });

    return selected;
  };

  const handleSelect = (keys) => {
    if (keys && keys.length) {
      const key = keys[0];
      const selected = getSelectedValue(key);

      setSelectedValue(key);
      setAreaCode(selected.code)
      setTreeVisibility(false);
    }
  };

  const toggleTreeVisibility = () => {
    setTreeVisibility(!treeVisibility);
  };

  return (
    <div className="form-selects-wrapper-row">
      <FilterHeader
        name="Area"
      />

      <div
        onClick={toggleTreeVisibility}
        className="filter-tree-selector"
      >
        {selectedValue}
        <Icon type={treeVisibility ? 'up' : 'down'} />
      </div>
      {treeVisibility && treeData && (
        <TreeView
          data={treeViewData}
          handleSelect={handleSelect}
          setTreeFilterData={setTreeViewData}
        />
      )}
    </div>
  );
};

export default AreaSelect;
