import { useSelector } from "react-redux";
import L from 'lodash';
import FP from 'lodash/fp';

// Clients
import { getSeasons } from "@/clients/DataProduct/environments/seasons";

// Common
import { Awaited } from "@/types/common";
import { getCompetitions } from "@/clients/DataProduct/environments/competitions";
import { getAreas } from "@/clients/DataProduct/environments/areas";

export type ReduxArea = Awaited<ReturnType<typeof getAreas>>['data'][number];
export type ReduxTournament = Awaited<ReturnType<typeof getCompetitions>>['data'][number];
export type ReduxSeason = Awaited<ReturnType<typeof getSeasons>>['data'][number];

export interface ReduxFilters {
  sport: unknown,
  area: ReduxArea, 
  tournament: ReduxTournament,
  season: ReduxSeason, 
}

const useFiltersStoreSelector = () => useSelector(
  L.flow(
    FP.get('filters'), 
    FP.defaultTo({})
  ) 
) as Partial<ReduxFilters>

export default useFiltersStoreSelector;