export const DEFAULT_CLUB_ATTRIBUTES = {
  title: '',
  sections: [{
    title: 'Names',
    name: 'attributes',
    rows: [],
  }],
};

export const DEFAULT_INCIDENTS_SCHEMA = {
  si: '',
  so: '',
  yc: '',
  rc: '',
  g: '',
  pg: '',
  p: '',
};
