import React from 'react';
import { Button } from 'antd';

import './SaveBar.scss';

const SaveBar = ({
  disabled = false,
  onSave = () => {},
}) => (
  <div className="save-bar-container">
    <Button
      className="save-button"
      disabled={disabled}
      onClick={onSave}
      type="primary"
    >
      Save
    </Button>
  </div>
);

export default SaveBar;
