import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

const FilterHeader = ({ name = '', handleClearFilters = null }) => (
  <label>
    {name}
    {typeof handleClearFilters === 'function' && (
      <div className="filter-clear" onClick={handleClearFilters}>
        <Icon type="close" />
      </div>
    )}
  </label>
);

FilterHeader.propTypes = {
  name: PropTypes.string.isRequired,
  handleClearFilters: PropTypes.func,
};

export default FilterHeader;
