import { sortBy } from 'lodash';

import { getEventResult } from '../../utils/event';

import * as TYPES from '../actionTypes';

const INITIAL_STATE = {
  matrix: [],
  matrixGoals: [],
  matches: [],
};
const RANGE_FACTOR = 5; // 5% for each range

const predictionsSeasonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PREDICTIONS_SEASON_FETCHED:
      return {
        matrix: setPercentageRanges(action.payload),
        matrixGoals: setPercentageRangesGoals(action.payload),
        matches: sortBy(action.payload, 'event.startDate'),
      };

    case TYPES.PREDICTIONS_SEASON_CLEARED:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

const hasResult = ({ event }) => {
  const result = getEventResult(event);
  if (!result.length) return false;
  const [homeResult, awayResult] = result;

  if (homeResult && homeResult !== '' && awayResult && awayResult !== '') {
    return true;
  }
};

const filterData = (data = 0, start, end) => data * 100 >= start
    && data * 100 <= end;

const setPercentageRanges = (data) => {
  const ranges = 100 / RANGE_FACTOR;

  const results = [];
  for (let i = 0; i < ranges; i++) {
    const obj = {
      start: i * RANGE_FACTOR,
      end: i * RANGE_FACTOR + RANGE_FACTOR - 0.01,
    };

    obj.prediction = data.filter((d) => hasResult(d) && filterData(d.prediction.regularTime.home, obj.start, obj.end)).length
      + data.filter((d) => hasResult(d) && filterData(d.prediction.regularTime.away, obj.start, obj.end)).length
      + data.filter((d) => hasResult(d) && filterData(d.prediction.regularTime.draw, obj.start, obj.end)).length;

    obj.result = data.filter((d) => {
      if (hasResult(d)) {
        const [homeResult, awayResult] = getEventResult(d.event);

        if (homeResult > awayResult) {
          return filterData(d.prediction.regularTime.home, obj.start, obj.end);
        } if (homeResult < awayResult) {
          return filterData(d.prediction.regularTime.away, obj.start, obj.end);
        }
        return filterData(d.prediction.regularTime.draw, obj.start, obj.end);
      }
    }).length;

    results.push(obj);
  }

  const totalPredictions = data.length * 3;

  for (let i = 0; i < results.length; i++) {
    results[i].percentage_of_total = results[i].prediction ? (results[i].prediction / totalPredictions) * 100 : 0;
    results[i].percentage_of_predictions = results[i].result ? (results[i].result / results[i].prediction) * 100 : 0;
  }

  return results;
};

const setPercentageRangesGoals = (data) => {
  const teams = ['home', 'away'];

  const ranges = [
    { start: 0, end: 0.4999 },
    { start: 0.5, end: 1.4999 },
    { start: 1.5, end: 2.4999 },
    { start: 2.5, end: 3.4999 },
    { start: 3.5, end: 4.4999 },
    { start: 4.5, end: 10.9999 },
  ];

  const results = [];
  let totalResults = 0;
  for (let i = 0; i < ranges.length; i++) {
    const obj = {
      ...ranges[i],
      prediction: 0,
      result: 0,
    };

    results.push(obj);
  }

  data.forEach((d) => {
    const [homeResult, awayResult] = getEventResult(d.event);

    if (homeResult && awayResult) {
      teams.forEach((team) => {
        const scoringPrediction = d.scoringPrediction.regularTime;
        if (scoringPrediction && scoringPrediction[team]) {
          scoringPrediction[team].forEach((dg, dgIdx) => {
            if (dgIdx < 5) {
              results[dgIdx].prediction += dg;
            } else {
              results[5].prediction += dg;
            }
          });
        }
      });

      results.forEach((result) => {
        if (homeResult >= result.start && homeResult <= result.end) {
          result.result += 1;
          totalResults += 1;
        }

        if (awayResult >= result.start && awayResult <= result.end) {
          result.result += 1;
          totalResults += 1;
        }
      });
    }
  });

  for (let i = 0; i < results.length; i++) {
    results[i].percentage_of_total = results[i].prediction ? (results[i].prediction / totalResults) * 100 : 0;
    results[i].percentage_of_predictions = results[i].result ? (results[i].result / totalResults) * 100 : 0;
  }

  return results;
};

export default predictionsSeasonReducer;
