import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { DEFAULT_FILTER_VALUE } from '../../constants/filters';
import storage from '../../utils/localStorage';
import { setFilters } from '../../store/actions/filters';
import FilterHeader from './FilterHeader';

import './Filters.scss';

const SelectFilter = ({ name = '', list = [], placeholder }) => {
  const filters = useSelector(({ filters }) => filters);
  const dispatch = useDispatch();

  const storageFilters = JSON.parse(storage.getItem('filters')) || {};

  const selectedFilter = (storageFilters[name] && storageFilters[name].name) || (filters[name] && filters[name].name);
  const [selectedValue, setSelectedValue] = useState(selectedFilter || placeholder || DEFAULT_FILTER_VALUE);

  const handleValueSelect = (value) => {
    if (value) {
      const selected = list.find((item) => item.uuid === value);
      dispatch(setFilters(name, selected));
    }
  };

  useEffect(() => {
    if (placeholder) {
      setSelectedValue(placeholder);
    }
  }, [placeholder]);

  return (
    <div className="filters-row">
      <FilterHeader name={name} />

      {list && (
        <Select defaultValue={selectedValue} value={selectedValue} onChange={handleValueSelect}>
          {list.map((obj) => (
            <Select.Option key={obj.uuid} value={obj.uuid}>{obj.name}</Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

SelectFilter.propTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.array,
};

export default SelectFilter;
