import React from 'react';
import { 
  Form,
  Select,
  DatePicker,
  Button,
 } from 'antd';
 import moment from 'moment';
 import 'antd/dist/antd.css';

 import { simpleTransferPlayer } from '@/store/actions/players';

 import '@/components/Form/Form.scss';

 class DataForm extends React.Component {

  handleSubmit = async e => {
    e.preventDefault();
    const {playerUuid, clubUuid, dispatchPlayerTransfers} = this.props

    const toDate = this.props.form.getFieldValue('to')
    const fromDate = this.props.form.getFieldValue('from')
    const type = this.props.form.getFieldValue('type');
    const dateToObj = toDate ? { to: moment(toDate).format('YYYY-MM-DD')} : {};
    const dateFromObj = fromDate ? { from: moment(fromDate).format('YYYY-MM-DD')} : {};

    const requestValues = {
      type,
      ...dateToObj,
      ...dateFromObj,
      destinationTeamUuid: clubUuid,
    }
    await simpleTransferPlayer(playerUuid, requestValues);
    await dispatchPlayerTransfers(playerUuid);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form
        onSubmit={this.handleSubmit}
      >
        <Form.Item className="label-colored-row" label="type" name="type">
          {getFieldDecorator('type', {
            rules: [{ required: true, message: 'Please select transfer type!' }],
          })(
            <Select>
              <Select.Option value="loan">loan</Select.Option>
              <Select.Option value="endLoan">end of Loan</Select.Option>
              <Select.Option value="free">free</Select.Option>
              <Select.Option value="regular">regular</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item className="label-colored-row"label="Start date in that club | When player was transfered, loaned" name="from">
          {getFieldDecorator('from', {
            rules: [{ required: true, message: 'Field is required!' }],
          })(
            <DatePicker />
          )}
        </Form.Item>
        <Form.Item className="label-colored-row"label="End day of transfer, loan | Till when player was in that club" name="to">
          {getFieldDecorator('to', {
            rules: [{ required: false }],
          })(
            <DatePicker />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Transfer player
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const NewPlayerTransformForm = Form.create({ name: 'new_player_transfer' })(DataForm);
export default NewPlayerTransformForm;