import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'lodash';
import FP from 'lodash/fp';

// Components
import { EditableTable } from '@/components';

// Clients
import { Fixture } from '@/clients/DataProduct/fixtures/types';
import { tempResponseConverter as fixtureConverter } from '@/clients/DataProduct/fixtures/events';

// Common
import { ReduxTournament } from '@/hooks/useFiltersStoreSelector';
import { FIXTURE_TOOLTIPS } from '@/constants/tooltips';
import { UUID } from '@/types/common';

// Internal
import { WidgetFixtureFilterData } from '@/views/Demo/Demo.types';

interface FixturesListProps {
  activeFixtureFilters: Partial<WidgetFixtureFilterData>, 
  onFixtureTableSelection: ([rowUuid]: [UUID], [rowData]: [ReturnType<typeof fixtureConverter>[number]]) => void
}

const FixturesList: FC<FixturesListProps> = ({ activeFixtureFilters, onFixtureTableSelection }) => {
  const selectedTournament = useSelector(FP.get('tournaments.selected')) as ReduxTournament & { fixtures: Fixture[] };

  return (
    <div className="fixtures-container">
      <EditableTable
        isRowCounterEnabled
        pagination={{ pageSize: 10 } as never}
        columnsNotToShow={['id', 'statscore_id', 'status', 'FT']}
        columnsWidth={[100, 100, 180, 180]}
        data={{ rows: selectedTournament?.fixtures || [], title: 'Fixtures' }}
        handleTableChange={L.noop}
        rowSelection={{
          selectedRowKeys: [activeFixtureFilters?.eventUUID],
          onChange: onFixtureTableSelection,
          type: 'radio',
        }}
        scrollProps={{ y: 640 }}
        tooltips={FIXTURE_TOOLTIPS}
      />
    </div>
  )
}

export default FixturesList;