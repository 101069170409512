import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import L from 'lodash';
import FP from 'lodash/fp';

// Clients
import { WidgetType } from '@/clients/CmsAPI/widgets/types';
import { Fixture } from '@/clients/DataProduct/fixtures/types';
import { getFixtures, tempResponseConverter } from '@/clients/DataProduct/fixtures/events';

// Common
import { UUID } from '@/types/common';
import { NUMBER_OF_MATCHES_IN_WEEK_DAY, NUMBER_OF_ODDSEN_IN_WEEK_DAY } from '@/constants/demo';
import useFiltersStoreSelector, { ReduxTournament } from '@/hooks/useFiltersStoreSelector';

// Internal
import './Demo.scss';
import { WidgetFixtureFilterData } from './Demo.types';
import { CalendarList } from './components/Calendar';
import Header from './components/Header';
import useWidgetsDraft from './hooks/useWidgetsDraft';
import useActiveDraft from './hooks/useActiveDraft';
import useActiveFixtureSync from './hooks/useActiveFixtureSync';
import FixturesList from './components/FixturesList';

const DemoView: FC = () => {
  // Redux selectors
  const selectedTournament = useSelector(FP.get('tournaments.selected')) as ReduxTournament & { fixtures: Fixture[] };
  const { area: selectedArea, season: selectedSeason } = useFiltersStoreSelector();

  // State - Draft
  const matchDraft = useWidgetsDraft(NUMBER_OF_MATCHES_IN_WEEK_DAY);
  const oddsenDraft = useWidgetsDraft(NUMBER_OF_ODDSEN_IN_WEEK_DAY);

  // State - Active Widget
  const [
    [activeWidgetType, setActiveWidgetType],
    [activeFixtureRow, setActiveFixtureRow],
    [activeWidgetsDraft, shouldSaveActiveWidgetsDraft, setActiveWidgetsDraft]
  ] = useActiveDraft({
    [WidgetType.MATCH]: matchDraft,
    [WidgetType.ODDS]: oddsenDraft
  })

  // State - Filters Map
  const [widgetsFixturesFiltersMap, setWidgetsMap] = useState<Record<UUID, WidgetFixtureFilterData>>({});
  const setWidgetsFixturesFiltersMap: typeof setWidgetsMap = L.flow(L.cloneDeep, setWidgetsMap);
  const activeFixtureFilters = useMemo(
    () => {
      const { date, rowIdx } = activeFixtureRow;
      return L.get(
        widgetsFixturesFiltersMap,
        L.get(activeWidgetsDraft, [date, rowIdx])
      );
    },
    [
      activeFixtureRow, 
      activeWidgetsDraft,
      widgetsFixturesFiltersMap
    ]
  ) as Partial<WidgetFixtureFilterData>;

  // Sync filters and Fetch fixtures
  useActiveFixtureSync(
    activeFixtureFilters,
    activeFixtureRow
  );

  // Should fetch Fixtures based on active widget uuids
  useEffect(
    () => {
      const notMappedEventUuids = L.flow(
        L.values,
        L.flatten,
        L.compact,
        FP.filter(L.negate(L.partial(L.has, widgetsFixturesFiltersMap)))
      )(activeWidgetsDraft) as UUID[];

      if (notMappedEventUuids.length) {
        const transformIntoTuple = ({ home, away, season, event }: Fixture) => [
          event?.uuid,
          {
            homeTeamName: home?.teamDetails?.displayedName || home?.name,
            awayTeamName: away?.teamDetails?.displayedName || away?.name,
            areaUUID: season?.area?.uuid,
            competitionUUID: season?.competition?.uuid,
            seasonUUID: season?.uuid,
            eventUUID: event?.uuid
          }
        ] as [UUID, WidgetFixtureFilterData]

        getFixtures({ eventUuid: notMappedEventUuids })
          .then(FP.get('data'))
          .then(FP.map(transformIntoTuple))
          .then(
            FP.reduce(
              (acc, [key, value]) => L.set(acc, key, value),
              widgetsFixturesFiltersMap
            )
          )
          .then(data => {
            setWidgetsFixturesFiltersMap(data as unknown as Record<UUID, WidgetFixtureFilterData>)
          });
      }
    },
    [activeWidgetsDraft]
  );

  const onFixtureTableSelection = useCallback(
    ([rowUuid]: [UUID], [rowData]: [ReturnType<typeof tempResponseConverter>[number]]) => {
      if (L.negate(L.has)(widgetsFixturesFiltersMap, rowUuid)) {
        setWidgetsFixturesFiltersMap(L.set(
          widgetsFixturesFiltersMap, 
          rowUuid, 
          {
            homeTeamName: rowData?.home,
            awayTeamName: rowData?.away,
            eventUUID: rowUuid,
            competitionUUID: selectedTournament.id,
            seasonUUID: selectedSeason.id,
            areaUUID: selectedArea.id
          }
        ));
      }

      setActiveWidgetsDraft(L.set(
        activeWidgetsDraft, 
        [activeFixtureRow?.date, activeFixtureRow?.rowIdx], 
        rowUuid
      ));

    },
    [
      widgetsFixturesFiltersMap,
      activeWidgetsDraft,
      activeFixtureRow,
      selectedTournament,
      selectedSeason,
      selectedArea
    ]
  );

  // Calendar renderer memo fn
  const tabCalendarContent = useMemo(
    () => (
      <CalendarList {...{
        activeWidgetsDraft,
        widgetsFixturesFiltersMap,
        activeWidgetType,
        activeFixtureRow,
        setActiveFixtureRow
      }}/>
    ),
    [
      activeWidgetsDraft,
      widgetsFixturesFiltersMap,
      activeWidgetType,
      activeFixtureRow,
      setActiveFixtureRow
    ]
  )

  // Table renderer memo fn
  const tabFixturesContent = useMemo(
    () => (activeFixtureRow?.date && activeFixtureRow?.type === activeWidgetType) && (
      <FixturesList {...{
        activeFixtureFilters, 
        onFixtureTableSelection
      }}/>
    ),
    [
      activeFixtureRow,
      activeFixtureFilters,
      activeWidgetType,
      onFixtureTableSelection
    ]
  );

  const tabContent = useMemo(
    () => (
      <div className="demo-fixtures-section">
        {tabCalendarContent}
        {tabFixturesContent}
      </div>
    ),
    [
      tabCalendarContent,
      tabFixturesContent,
    ]
  );

  return (
    <section className="demo-container">
      <Header {...{
        activeWidgetType,
        activeWidgetsDraft,
        shouldSaveActiveWidgetsDraft
      }}/>

      <Tabs
        defaultActiveKey={WidgetType.MATCH}
        onChange={(key: WidgetType) => setActiveWidgetType(key)}
      >
        <Tabs.TabPane tab="Matches selection" key={WidgetType.MATCH} children={tabContent} />
        <Tabs.TabPane tab="Oddsen selection" key={WidgetType.ODDS} children={tabContent} />
      </Tabs>
    </section>
  )
}

export default DemoView;