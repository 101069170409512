import * as TYPES from './../actionTypes';
import { uniqBy } from 'lodash';

import { sortByKey } from './../../utils/objects';

const INITIAL_STATE = {
  list: [],
  selected: {
  },
};

const playersReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.PLAYERS_FETCHED:
      return {
        ...state,
        list: sortByKey(uniqBy(action.payload, 'uuid'), 'name'),
      };

    case TYPES.PLAYERS_CLEAR:
      return {
        ...state,
        list: [],
        selected: INITIAL_STATE.selected,
      };

    case TYPES.PLAYERS_SELECT:
      return {
        ...state,
        selected: state.list?.find(player => player?.uuid === action.payload) || {},
      };
    
    case TYPES.PLAYER_TRANSFERS:
      return {
        ...state,
        selectedTransfers: action.payload,
      };

    case TYPES.PLAYERS_UPDATED: {
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    }

    case TYPES.PLAYERS_ADDED: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    default:
      return state;
  }
};

export default playersReducer;