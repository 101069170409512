const scoreFormData = {
  title: 'Score',
  sections: [{
    title: '',
    name: 'score',
    rows: [{
      label: 'Score type',
      fields: [{
        type: 'select',
        options: ['goals'],
        placeholder: 'Score',
        value: '{{score_type}}',
        name: 'score_type',
      }],
    }, {
      label: 'Score range',
      fields: [{
        type: 'number',
        placeholder: 'min',
        default: 0,
        size: 'small',
        name: 'score_min',
        value: '{{score_min}}',
        min: 0,
      }, {
        type: 'number',
        placeholder: 'max',
        default: 99,
        size: 'small',
        name: 'score_max',
        value: '{{score_max}}',
        max: 100,
      }],
    }],
  }],
};

export default scoreFormData;
