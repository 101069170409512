import * as TYPES from '../actionTypes';

const INITIAL_STATE = [];

const sportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SPORTS_FETCHED:
      return [
        ...action.payload,
      ];

    case TYPES.SPORT_PREDICTION_FETCHED:
      return [
        ...state.map((sport) => {
          if (sport.uuid === action.payload.sportId) {
            sport.predictionParameters = action.payload.parameters;
          }
          return sport;
        }),
      ];

    default:
      return state;
  }
};

export default sportsReducer;
