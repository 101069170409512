import L from 'lodash';
import FP from 'lodash/fp';
import { createSlice } from "@reduxjs/toolkit";

import { getWidgetsInfo, putWidgetsInfo, deleteWidgetInfo } from "@/clients/CmsAPI/widgets";
import { WidgetType } from "@/clients/CmsAPI/widgets/types";
import { createAsyncThunkWithErrorComposer } from "@/utils/errorComposer";
import { DateISOString, UUID } from '@/types/common';
import { returnAxiosResponseDataWithParams, useBasicSlice } from '@/utils/store';

export type WidgetsInfoRecords = Record<DateISOString, UUID[]>;

export interface WidgetsState {
  matchesWidgetsInfo: WidgetsInfoRecords;
  oddsenWidgetsInfo: WidgetsInfoRecords;
}

const initialState: WidgetsState = {
  matchesWidgetsInfo: {},
  oddsenWidgetsInfo: {}
};

export const MAP_TYPES_TO_STATE: Record<WidgetType, keyof WidgetsState> = {
  [WidgetType.MATCH]: 'matchesWidgetsInfo',
  [WidgetType.ODDS]: 'oddsenWidgetsInfo',
};

const fetchWidgetsInfo = createAsyncThunkWithErrorComposer(
  'get/widgets',
  returnAxiosResponseDataWithParams(getWidgetsInfo)
);

const updateWidgetsInfo = createAsyncThunkWithErrorComposer(
  'put/widgets',
  returnAxiosResponseDataWithParams(putWidgetsInfo)
);

const removeWidgetsDateInfo = createAsyncThunkWithErrorComposer(
  'delete/widgets',
  returnAxiosResponseDataWithParams(deleteWidgetInfo)
);

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchWidgetsInfo.fulfilled,
        (state, { payload }) => {
          const { params, data } = payload;

          const covertedData = L.flow(
            FP.groupBy('date'),
            FP.mapValues(L.flow(
              FP.map('eventUuid'),
              FP.filter(Boolean)
            )),
            FP.omitBy(L.isEmpty)
          )(data) as WidgetsInfoRecords;

          L.set(state, MAP_TYPES_TO_STATE[params.type], covertedData);
        }
      )
      .addCase(
        updateWidgetsInfo.fulfilled,
        (state, { payload }) => {
          const { matches, type } = payload.params;

          L.forEach(
            matches,
            ({ date, ids }) => {
              L.set(state, [MAP_TYPES_TO_STATE[type], date], ids)
            }
          );
          
        }
      )
      .addCase(
        removeWidgetsDateInfo.fulfilled,
        (state, { payload }) => {
          const { type, date } = payload.params;
          L.unset(state, [MAP_TYPES_TO_STATE[type], date]);
        }
      );
  }
});

const widgetsActions = {
  fetchWidgetsInfo,
  updateWidgetsInfo,
  removeWidgetsDateInfo
};

export const useWidgetsSlice = () => (
  useBasicSlice<WidgetsState, typeof widgetsActions>('widgets', widgetsActions)
);

export default widgetsSlice.reducer;