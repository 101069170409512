const predictionsFormData = {
  title: 'Predictions',
  sections: [{
    title: 'Ground importance',
    name: 'groundImportance',
    rows: [{
      label: 'Home',
      fields: [
        {
          type: 'number', placeholder: 'home', value: '{{home_ground_importance}}', size: 'small', name: 'home_ground_importance', min: 0,
        },
      ],
      id: 0,
    }, {
      label: 'Neutral',
      fields: [
        {
          type: 'number', placeholder: 'neutral', value: '{{neutral_ground_importance}}', size: 'small', name: 'neutral_ground_importance', min: 0,
        },
      ],
      id: 1,
    }, {
      label: 'Away',
      fields: [
        {
          type: 'number', placeholder: 'away', value: '{{away_ground_importance}}', size: 'small', name: 'away_ground_importance', min: 0,
        },
      ],
      id: 2,
    }],
  }, {
    title: 'Competition & Tournament level',
    name: 'levels',
    rows: [{
      label: 'Competition level',
      fields: [
        {
          type: 'decimal', placeholder: 'competition level', value: '{{competition_level}}', size: 'small', name: 'competition_level', min: 0.01,
        },
      ],
      id: 0,
    }, {
      label: 'Offensive competition average',
      fields: [
        {
          type: 'number', placeholder: 'offensive competition average', value: '{{offensive_competition_average}}', size: 'small', name: 'offensive_competition_average', min: 0,
        },
      ],
      id: 1,
    }],
  }],
};

export default predictionsFormData;
