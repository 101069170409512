const attributesFormData = {
  title: '',
  sections: [{
    title: 'Name',
    name: 'attributes',
    rows: [{
      label: 'Continent',
      editable: false,
      fields: [{ type: 'text', name: 'parent', value: '{{parentName}}' }],
    }, {
      label: 'Name',
      editable: false,
      fields: [{ type: 'text', name: 'name', value: '{{name}}' }],
    }, {
      label: 'Short name',
      editable: false,
      fields: [{ type: 'text', name: 'code', value: '{{code}}' }],
    }],
  }],
};

export default attributesFormData;
