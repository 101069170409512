import { Team } from "@/clients/DataProduct/common/types";
import { UUID, DateISOString } from "@/types/common";
import { Season } from "@/clients/DataProduct/environments/types";

export enum EventStatus {
  CANCELLED = 'cancelled',
  SCHEDULED = 'scheduled',
  INTERRUPTED = 'interrupted',
  LIVE = 'live',
  FINISHED = 'finished',
}


export interface EventDetail {
  name: string;
  value: string;
  uuid: UUID;
}

export type FixtureTeam = Pick<Team, 'uuid' | 'name' | 'shortName' | 'type'> & {
  teamDetails: Team;
}

export type Event = {
  details: EventDetail[];
  uuid: UUID;
  result: string;
  startDate: DateISOString;
  name: string;
  day: string;
  status: EventStatus;
}

export type Fixture = {
  event: Event;
  prediction: {
    draw: number;
    home: number;
    away: number;
  };
  home: FixtureTeam;
  away: FixtureTeam;
  season: Season;
}

export type EventPredictions = {
  event: Event;
  home: FixtureTeam;
  away: FixtureTeam;
  season: Season;
  prediction: unknown;
  scoringPrediction: unknown;
  goalsFactor: unknown;
  correctScore: unknown;
}