import moment from 'moment';
import React, { useCallback } from 'react';

import { DATE_FORMAT, TIME_FORMAT } from '../../constants/time';
import { getEventResult } from '../../utils/event';

const AnalyticsMatches = ({ predictionsSeason }) => {
  const stylesActive = { color: '#4B8E19', fontWeight: 700, fontSize: 12 };

  const formatNumber = (number = 0) => parseFloat(number * 100).toFixed(2);

  const getMatchWinnerPrediction = useCallback((season) => {
    const [homeResult, awayResult] = getEventResult(season.event);

    if (homeResult > awayResult) {
      return season.prediction.regularTime.home;
    } if (homeResult < awayResult) {
      return season.prediction.regularTime.away;
    }
    return season.prediction.regularTime.draw;
  });

  return (
    <ul className="analytics matches">
      <li className="list-header">
        <div className="row">
          <div style={{ width: 100 }} />
          <div style={{ width: 300 }} />
          <div style={{ width: 240, paddingLeft: 70 }}>Prediction</div>
          <div style={{ width: 240, paddingLeft: 80 }}>Prediction on result</div>
        </div>
      </li>
      <li className="list-header">
        <div className="row">
          <div className="date">Date</div>
          <div className="match">Match</div>
          <div className="prediction">1</div>
          <div className="prediction">X</div>
          <div className="prediction">2</div>
          <div className="result">Result</div>
          <div className="result">Prediction</div>
        </div>
      </li>
      {predictionsSeason.map((season, idx) => {
        const [homeResult, awayResult] = getEventResult(season.event);

        return (
          <li key={`match_${idx}`}>
            <div className="row">
              <div className="date">{moment(season.event.startDate).format(`${DATE_FORMAT} ${TIME_FORMAT}`)}</div>
              <div className="match">{season.event.name}</div>
              <div className="prediction" style={homeResult > awayResult ? stylesActive : {}}>
                {formatNumber(season.prediction.regularTime.home)}
                {' '}
                <span>%</span>
              </div>
              <div className="prediction" style={homeResult === awayResult ? stylesActive : {}}>
                {formatNumber(season.prediction.regularTime.draw)}
                {' '}
                <span>%</span>
              </div>
              <div className="prediction" style={homeResult < awayResult ? stylesActive : {}}>
                {formatNumber(season.prediction.regularTime.away)}
                {' '}
                <span>%</span>
              </div>
              <div className="result">{season.event.result}</div>
              <div className="result bold">
                {formatNumber(getMatchWinnerPrediction(season))}
                {' '}
                <span>%</span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default AnalyticsMatches;
