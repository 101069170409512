import * as TYPES from '../actionTypes';

const INITIAL_STATE = {
  tree: [],
  selected: {},
};

const setSelectedArea = (areaId, list) => {
  let selected = { id: areaId };
  list.forEach((parent) => {
    parent.children.forEach((area) => {
      if (area.id === areaId) {
        selected = {
          ...area,
          parentName: parent.name,
        };
      }
    });
  });

  return selected;
};

const areasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.AREAS_FETCHED:
      return {
        ...state,
        tree: action.payload,
      };

    case TYPES.AREAS_CLEAR:
      return {
        ...state,
        tree: [],
      };

    case TYPES.AREA_SELECT:
      return {
        ...state,
        selected: setSelectedArea(action.payload, state.tree),
      };

    case TYPES.IMAGE_COUNTRY_ADDED:
      return {
        ...state,
        selected: {
          ...state.selected,
          badgeUrl: action.payload,
        },
      };

    case TYPES.IMAGE_COUNTRY_DELETED:
      delete state.selected.badgeUrl;

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    default:
      return state;
  }
};

export default areasReducer;
