import * as TYPES from '../actionTypes';

const INITIAL_STATE = {
  sport: {},
};

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SET_FILTERS:
      return {
        ...action.payload,
      };

    case TYPES.SET_URL_FILTERS:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.CLEAR_FILTERS:
      const updatedState = { ...state };
      action.payload.map((filter) => delete updatedState[filter]);

      return {
        ...updatedState,
      };

    default:
      return state;
  }
};

export default filtersReducer;
