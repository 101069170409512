import React from 'react';
import { compose } from 'redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { history } from './store';
import { withLayout } from './hocs';
import {
  Analytics, Configuration, Demo, Packages, Setup, Tournaments,
  TournamentsRanking, Players
} from './views';

import 'antd/dist/antd.css';
import './styles/app.scss';
import 'axios-progress-bar/dist/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

const withHOCs = Component => compose<unknown>(
  withAuthenticationRequired,
  withLayout,
)(Component);

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/setup" component={withHOCs(Setup)} />
        <Route path="/configuration" component={withHOCs(Configuration)} />
        <Route path="/tournaments" component={withHOCs(Tournaments)} />
        <Route path="/tournaments-ranking" component={withHOCs(TournamentsRanking)} />
        <Route path="/demo" component={withHOCs(Demo)} />
        <Route path="/packages" component={withHOCs(Packages)} />
        {/* <Route path="/prediction" component={withHOCs(Prediction)} /> */}
        <Route path="/analytics" component={withHOCs(Analytics)} />
        <Route path="/players" component={withHOCs(Players)} />

        <Redirect from="/" to="/setup" />
        <Route component={withHOCs(Setup)} />
      </Switch>
    </Router>
  );
};

export default Routes;
