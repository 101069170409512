import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { selectPlayer } from '@/store/actions/players';

import { fetchClubs } from '../../../store/actions/clubs';

const { Option } = Select;

const ClubSelect = ({club, setClub}) => {
  const clubs = useSelector(({ clubs }) => clubs);
  const filters = useSelector(({ filters }) => filters);
  const { season } = filters;
  const dispatch = useDispatch();

  useEffect(
    () => {
      if(season) {
        dispatch(fetchClubs({seasonId: season.id}));
      } 
    },
    [season]
  );

  const handleCLubSelect = useCallback(
    value => {
      setClub(value)
      dispatch(selectPlayer());
    },
    [setClub, selectPlayer]
  );

  return (
    <Select
      defaultValue={club}
      style={{ width: '100%' }}
      onChange={handleCLubSelect}
    >
      <Option value=""> - none - </Option>
      {clubs.list.map(
        club => (
          <Option key={club.id} value={club.id}>
            {club.displayedName}
          </Option>
        )
      )}
    </Select>
  );
};

export default ClubSelect;