export const spacesToUnderscore = (str) => str && str.replace(/ /g, '_').toLowerCase();

export const addSpaceToCamelCase = (camelCaseString) => camelCaseString && camelCaseString.replace(/([A-Z])/g, ' $1');

export const isStringImage = (str) => str && str.match(/\.(jpeg|jpg|gif|png)$/) !== null;

export const camelCaseToUnderscore = (str) => str && str.trim().split(/(?=[A-Z])/).join('_').toLowerCase();

export const removeAllWhitespaces = (str) => str && str.replace(/\s/g, '');

export const convertObjectKeysToUnderscore = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key].constructor.name === 'Object') {
      obj[key] = convertObjectKeysToUnderscore(obj[key]);
    }
    newObj[camelCaseToUnderscore(key)] = obj[key];
  });

  return newObj;
};

export const underscoreToCamelCase = (str) => str && str.trim().replace(/_([a-z])/g, (m, w) => w.toUpperCase());

export const convertObjectKeysToCamelCase = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key].constructor.name === 'Object') {
      obj[key] = convertObjectKeysToCamelCase(obj[key]);
    }
    newObj[underscoreToCamelCase(key)] = obj[key];
  });

  return newObj;
};

export const getInitials = (string) => string.split(' ').map((word) => word.charAt(0).toLowerCase()).join('');

export const convertToInt = (val) => parseInt(val, 10);

export const convertToDecimal = (val, places = 4) => parseFloat(val).toFixed(places);

export const convertObjectValuesToInt = (data) => {
  const updatedData = {};
  Object.keys(data).forEach((key) => {
    updatedData[key] = convertToInt(data[key]);
  });

  return updatedData;
};
