const attributesFormData = {
  title: '',
  sections: [{
    title: 'Tournament',
    name: 'attributes',
    rows: [{
      label: 'Short name',
      editable: false,
      fields: [{ type: 'text', name: 'abbrev', value: '{{abbrev}}' }],
    }, {
      label: 'Name',
      editable: false,
      fields: [{ type: 'text', name: 'name', value: '{{name}}' }],
    }, {
      label: 'Type',
      editable: false,
      fields: [{ type: 'text', name: 'type', value: '{{type}}' }],
    }, {
      label: 'Area type',
      editable: false,
      fields: [{ type: 'text', name: 'areaType', value: '{{areaType}}' }],
    }, {
      label: 'Gender',
      editable: false,
      fields: [{ type: 'text', name: 'gender', value: '{{gender}}' }],
    }],
  }, {
    title: 'Predictions',
    name: 'predictions',
    rows: [{
      label: 'Pred. Importance',
      fields: [{
        type: 'number',
        placeholder: 'Enter importance',
        name: 'prediction_importance',
        value: '{{predictionImportance}}',
        size: 'small',
      }],
    }, {
      label: 'Pred. Level',
      fields: [{
        type: 'number',
        placeholder: 'Enter level',
        name: 'prediction_level',
        value: '{{predictionLevel}}',
        size: 'small',
      }],
    }, {
      label: 'League Level',
      fields: [{
        type: 'number',
        placeholder: 'Enter level',
        name: 'league_level',
        value: '{{leagueLevel}}',
        size: 'small',
      }],
    }],
  }],
};

export default attributesFormData;
