import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {
  Button, Checkbox, DatePicker, Popconfirm, Select, Table, TimePicker, Tooltip,
} from 'antd';

import { DATE_FORMAT, TIME_FORMAT } from '../../constants/time';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';

import { EditableFormRow, EditableTableCell } from './EditableTableCell';

import './EditableTable.scss';

const { Option } = Select;

const EditableTable = ({
  areRowsAddable = false,
  areRowsDeletable = false,
  columnsNotToShow = [],
  columnsWidth = [],
  data: { rows = [], title = '' },
  handleTableChange = () => {},
  isEditable = false,
  isNewRowAdded = false,
  isRowCounterEnabled = true,
  pagination = false,
  rowSelection = null,
  scrollProps = {},
  tooltips = {},
}) => {
  const prepareData = (data) => {
    if (data.length) {
      data.forEach((record) => {
        for (const key in record) {
          if (record[key] === null || record[key] === undefined) {
            record[key] = '';
          }
        }
      });

      return [...data];
    }
    return [];
  };

  const [dataSource, setDataSource] = useState(prepareData(rows));
  const [count, setCount] = useState(rows.length);
  const [finalColumns, setFinalColumns] = useState([]);
  const [areRowsChanged, setAreRowsChanged] = useState(isNewRowAdded);
  const clickedRowRef = useRef();

  useDidUpdateEffect(() => {
    // if (rows && rows.length) {
    // }
    setDataSource(prepareData(rows));
    setCount(rows.length);
  }, [rows]);

  useEffect(() => {
    setAreRowsChanged(isNewRowAdded);
  }, [isNewRowAdded]);

  const EXAMPLARY_ADD_DATA = {
    id: count,
    date: moment().format(DATE_FORMAT),
    time: moment().format(TIME_FORMAT),
    round: '',
    home: {
      options: ['Brandtford Galaxy', 'London City', 'Edmonton'],
      chosenOption: '',
    },
    away: {
      options: ['Brandtford Galaxy', 'London City', 'Edmonton'],
      chosenOption: '',
    },
    success: { isSelected: false },
    approved: { isSelected: false },
  };

  useDidUpdateEffect(() => {
    if (areRowsChanged) {
      const dataToSend = {
        data: dataSource,
        title,
      };

      handleTableChange(dataToSend);
      setAreRowsChanged(false);
    }
  }, [dataSource]);

  const renderSelectInput = (selectOptions, selectOptionsKey) => (
    <Select
      defaultValue={selectOptions.chosenOption}
      onChange={(selectedValue) => handleSelectChange(selectedValue, selectOptionsKey)}
    >
      {selectOptions.options.map((item, i) => (
        <Option
          key={item + i}
          value={item.toLowerCase()}
        >
          {item}
        </Option>
      ))}
    </Select>
  );

  const changeSelectedField = (key, value, isCheckbox) => {
    dataSource.forEach((record) => {
      if (record.id === clickedRowRef.current) {
        record[key] = isCheckbox ? { isSelected: !!value } : value;
      }
    });

    setAreRowsChanged(true);
    setDataSource([...dataSource]);
  };

  const generateRenderField = (key, value) => {
    if (isEditable) {
      if (value.options) {
        return (record) => renderSelectInput(record, key);
      }

      if (typeof value.isSelected === 'boolean') {
        return (record) => (
          <Checkbox
            checked={record && record.isSelected}
            onChange={(e) => changeSelectedField(key, e.target.checked, true)}
          />
        );
      }

      switch (key) {
        case 'date':
          return (record) => (
            <DatePicker
              onChange={(date, dateString) => changeSelectedField('date', dateString)}
              defaultValue={record ? moment(record, DATE_FORMAT) : null}
              format={DATE_FORMAT}
            />
          );

        case 'time':
          return (record) => (
            <TimePicker
              defaultValue={record ? moment(record, TIME_FORMAT) : null}
              format={TIME_FORMAT}
              onChange={(time, timeString) => changeSelectedField('time', timeString)}
            />
          );

        default:
          return null;
      }
    }
  };

  useEffect(() => {
    const preparedColumns = [];

    if (rows.length) {
      Object.entries(rows[0]).forEach(([key, value]) => {
        if (!columnsNotToShow.includes(key)) {
          const isEditableSelectInput = isEditable && (
            value.options || typeof value.isSelected === 'boolean' || key === 'date' || key === 'time');

          preparedColumns.push({
            title: <Tooltip title={tooltips[key]}>{key}</Tooltip>,
            dataIndex: key,
            editable: !isEditableSelectInput || null,
            render: generateRenderField(key, value),
          });
        }
      });

      if (areRowsDeletable) {
        preparedColumns.push({
          title: '',
          dataIndex: 'operation',
          render: (text, record) => ((dataSource.length >= 1 && areRowsDeletable) ? (
            <Popconfirm
              title="Are you sure you want to delete this line?"
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="clean" shape="circle" icon="delete" size="small" />
            </Popconfirm>
          ) : null),
        });
      }

      setFinalColumns(preparedColumns);
    }
  }, [
    areRowsDeletable,
    columnsNotToShow,
    rows,
    rows.length,
    dataSource,
  ]);

  const handleDelete = (key) => {
    const filteredDataSource = dataSource.filter((item) => item.id !== key);

    setAreRowsChanged(true);
    setDataSource(filteredDataSource);
  };

  const handleSelectChange = (selectedValue, optionsKey) => {
    dataSource.forEach((item) => {
      if (item.id === clickedRowRef.current) {
        item[optionsKey].chosenOption = selectedValue;
      }
    });

    setAreRowsChanged(true);
    setDataSource([...dataSource]);
  };

  const handleAdd = () => {
    setAreRowsChanged(true);
    setDataSource([...dataSource, EXAMPLARY_ADD_DATA]);
    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = dataSource.findIndex((item) => row.id === item.id);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setAreRowsChanged(true);
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableTableCell,
    },
  };

  const columns = finalColumns ? finalColumns.map((column, idx) => {
    const paddingValue = 10;
    const columnWidth = typeof columnsWidth[idx] === 'number'
      ? (columnsWidth[idx] + paddingValue)
      : columnsWidth[idx];

    if (!column.editable) {
      return {
        ...column,
        width: columnWidth,
      };
    }

    return {
      ...column,
      width: columnWidth,
      onCell: (record) => ({
        record,
        editable: column.editable,
        dataIndex: column.dataIndex,
        title: column.title,
        handleSave,
        editableInput: isEditable,
      }),
    };
  }) : [];

  const renderHeader = useCallback(() => {
    const counterElement = isRowCounterEnabled ? <span>{`(${dataSource.length})`}</span> : '';
    return (
      <h3>
        {title}
        {counterElement}
      </h3>
    );
  }, [
    dataSource,
    isRowCounterEnabled,
    title,
  ]);

  return (
    <div className="editable-table">
      <div className="meta-header-wrapper">
        {renderHeader()}
        {areRowsAddable && (
          <Button onClick={handleAdd} type="primary">
            Add
          </Button>
        )}
      </div>
      <Table
        bordered={false}
        columns={columns}
        components={components}
        dataSource={dataSource}
        onRow={(record, rowIndex) => ({
          // eslint-disable-next-line no-return-assign
          onClick: () => clickedRowRef.current = rowIndex,
        })}
        pagination={pagination}
        rowClassName={({ status }) => classnames('editable-row', status && status.toLowerCase())}
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        scroll={scrollProps}
      />
    </div>
  );
};

EditableTable.propTypes = {
  areRowsAddable: PropTypes.bool,
  areRowsDeletable: PropTypes.bool,
  columnsNotToShow: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnsWidth: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  data: PropTypes.object.isRequired,
  handleTableChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  isNewRowAdded: PropTypes.bool,
  isRowCounterEnabled: PropTypes.bool,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  rowSelection: PropTypes.object,
  scroll: PropTypes.object,
  tooltips: PropTypes.object,
};

export default EditableTable;
