import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import L from 'lodash';

// Internal
import CalendarCard from './CalendarCard';

// Common
import { DateISOString, UUID } from '@/types/common';
import { useWidgetsSlice, WidgetsInfoRecords } from '@/store/slices/widgets';
import { WidgetFixtureFilterData } from '@/views/Demo/Demo.types';
import { ActiveFixtureRow } from '../../hooks/useActiveDraft';
import { WidgetType } from '@/clients/CmsAPI/widgets/types';

interface CalendarListProps {
  activeWidgetsDraft: WidgetsInfoRecords,
  widgetsFixturesFiltersMap: Record<UUID, WidgetFixtureFilterData>,
  activeFixtureRow: ActiveFixtureRow,
  activeWidgetType: WidgetType,
  setActiveFixtureRow: Dispatch<SetStateAction<ActiveFixtureRow>>
}

const CalendarList: FC<CalendarListProps> = ({
  activeWidgetsDraft,
  widgetsFixturesFiltersMap,
  activeFixtureRow,
  activeWidgetType,
  setActiveFixtureRow
}) => {
  const { removeWidgetsDateInfo } = useWidgetsSlice();

  const getFixtureData = useCallback(
    (uuidArray: UUID[]) => L.map(
      uuidArray,
      uuid => L.get(widgetsFixturesFiltersMap, uuid)
    ),
    [widgetsFixturesFiltersMap]
  );

  const onRowSelect = useCallback(
    (newDate: DateISOString, newRowIdx: number) => setActiveFixtureRow({
      type: activeWidgetType,
      date: newDate,
      rowIdx: newRowIdx
    }),
    [activeWidgetType]
  );

  const onDayDelete = useCallback(
    (date: DateISOString) => removeWidgetsDateInfo({
      type: activeWidgetType,
      date
    }),
    [activeWidgetType]
  );

  return (
    <div className="week">
      {
        L.map(
          L.entries(activeWidgetsDraft),
          ([dateISO, uuidArray]) => {
            const cardActive = (
              L.eq(activeFixtureRow?.date, dateISO) &&
              L.eq(activeFixtureRow?.type, activeWidgetType)
            );

            return (
              <CalendarCard 
                key={dateISO}
                dateISO={dateISO as DateISOString}
                fixtureData={getFixtureData(uuidArray)}
                cardActive={cardActive}
                activeRowIdx={cardActive && activeFixtureRow?.rowIdx}
                onRowSelect={onRowSelect}
                onDayDelete={onDayDelete}
              />
            )
          }
        )
      }
    </div>
  )
}

export default CalendarList;