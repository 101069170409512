const attributesFormData = {
  title: 'Name',
  sections: [{
    title: '',
    name: 'attributes',
    rows: [{
      label: 'Name',
      fields: [{
        type: 'text',
        placeholder: 'Enter name',
        name: 'name',
        value: '{{name}}',
      }],
    }, {
      label: 'Price',
      fields: [{
        type: 'number',
        placeholder: 'Enter Price',
        name: 'price',
        value: '{{price}}',
        size: 'big',
      }],
    }, {
      label: 'Publication status',
      fields: [{
        type: 'select',
        options: ['not active', 'active'],
        placeholder: 'Status',
        value: '{{publicationStatus}}',
        name: 'publicationStatus',
      }],
    }],
  }],
};

export default attributesFormData;
