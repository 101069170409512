import { createStore } from '@reduxjs/toolkit';
import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import withReduxEnhancer from 'addon-redux/enhancer';

import reducers from './reducers';

const createHistory = require('history').createBrowserHistory;

export const history = createHistory();
const middleware = [thunk];

const enhancers = [];
enhancers.push(applyMiddleware(...middleware));
enhancers.push(applyMiddleware(routerMiddleware(history)));
if (process.env.NODE_ENV !== 'production') {
  enhancers.push(withReduxEnhancer);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers, composeEnhancers(
  ...enhancers,
));
