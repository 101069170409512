import * as TYPES from '../actionTypes';

const INITIAL_STATE = [];

const tournamentsRankingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.TOURNAMENT_RANKING_FETCHED:
      return [
        ...action.payload,
      ];

    case TYPES.TOURNAMENT_RANKING_CLEAR:
      return [
        ...INITIAL_STATE,
      ];

    default:
      return state;
  }
};

export default tournamentsRankingReducer;
