class Storage {
  constructor() {
    this.prefix = 'SPORTXPERT-ADMIN';
  }

  setItem = (key, value) => localStorage.setItem(`${this.prefix}_${key}`, value);

  getItem = (key) => localStorage.getItem(`${this.prefix}_${key}`);

  removeItem = (key) => localStorage.removeItem(`${this.prefix}_${key}`);

  hasKey = (key) => !!this.getItem(key);
}

export default new Storage();
