import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, Tree } from 'antd';
import { isEmpty, find, filter } from 'lodash';

import './TreeView.scss';

const { TreeNode } = Tree;

const getSelectedValueAreaName = (value, areas) => {
  const area = find(areas, ({ areas }) => areas?.some(area => area.name === value));
  return [area?.name]
}

const TreeView = ({
  areNodesAddable = false, data = [], handleAddNode, handleSelect, selectedKeys = [], setTreeFilterData, title = '',
  selectedTopValue, keepTreeExpandedAfterSelect = false,
}) => {
  const [isTreeNodeInputShown, setIsTreeNodeInputShown] = useState(false);
  const [treeViewData, setTreeViewData] = useState(data);
  const [newData, setNewData] = useState();
  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    setTreeViewData(data);
  }, [data]);

  useEffect(() => {
    !isEmpty(selectedTopValue) && !!keepTreeExpandedAfterSelect && setExpandedKeys(getSelectedValueAreaName(selectedTopValue, treeViewData))
  }, [selectedTopValue, treeViewData])

  const renderTitle = (item) => (
    <span className="tree-node-item">
      {item.name}
    </span>
  );

  const renderTreeNodes = (data) => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode key={item.name} title={renderTitle(item)} dataRef={item}>
          {renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        key={item.name}
        title={renderTitle(item)}
        dataRef={item}
        className={item.name === selectedTopValue ? 'selected' : ''}
      />
    );
  });

  const handleLeafSelect = (keys, { node }) => {
    if (!node.props.children) {
      handleSelect(keys);
    } else {
      const expanded = node.props.expanded ? [] : [node.props.eventKey];
      setExpandedKeys(expanded);
    }
  };

  const handleInputChange = (name) => {
    const newData = [
      ...treeViewData,
      { name },
    ];

    if (handleAddNode) {
      handleAddNode({
        title,
        data: newData,
      });
    } else {
      setNewData(newData);
    }
  };

  const addTreeNode = () => {
    if (newData && !handleAddNode) {
      setTreeViewData(newData);
      setTreeFilterData(newData);
      setIsTreeNodeInputShown(false);
    }
  };

  return (
    <section className="tree-view">
      {areNodesAddable && (
        <>
          <div className="icon-plus-container">
            <Icon type="plus-circle" onClick={() => setIsTreeNodeInputShown(true)} />
          </div>
          {isTreeNodeInputShown && (
            <Input onChange={(e) => handleInputChange(e.target.value)} onPressEnter={addTreeNode} />
          )}
        </>
      )}
      <Tree
        showLine
        expandedKeys={expandedKeys}
        switcherIcon={<Icon type="down-circle" />}
        onExpand={(expanded) => setExpandedKeys(expanded)}
        onSelect={handleLeafSelect}
        className="hide-file-icon"
        selectedKeys={selectedKeys}
      >
        {renderTreeNodes(treeViewData)}
      </Tree>
    </section>
  );
};

TreeView.propTypes = {
  areNodesAddable: PropTypes.bool,
  data: PropTypes.array.isRequired,
  handleAddNode: PropTypes.func,
  handleSelect: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  setTreeFilterData: PropTypes.func,
  title: PropTypes.string,
};

export default TreeView;
