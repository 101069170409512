import { groupBy } from 'lodash';
import moment from 'moment';

import * as TYPES from '../actionTypes';
import { NUMBER_OF_MATCHES_IN_WEEK_DAY } from '../../constants/demo';

const INITIAL_STATE = [];

const formatMatches = (payload) => {
  if (payload && payload.length) {
    const groupedMatchesByDate = groupBy(payload, 'date');
    return Object.entries(groupedMatchesByDate).map(([date, matches]) => ({
      date: moment(date).format('YYYY-MM-DD'),
      matches: matches.map((match) => ({
        ...match,
        date: moment(date).format('YYYY-MM-DD'),
      })),
    }));
    // return payload.map(matchDay => {
    //   const matchDayMatches = matchDay.matches
    //     ? matchDay.matches.map((match, idx) => {
    //       return {
    //         idx,
    //         id: match.id,
    //         home: match.home && match.home.team,
    //         away: match.away && match.away.team,
    //         areaId: match.area && match.area.id,
    //         tournamentId: match.tournament && match.tournament.id,
    //         seasonId: match.season && match.season.id,
    //       };
    //     })
    //     : [{ idx: 1 }, { idx: 2 }, { idx: 3 }];

    //   if(matchDayMatches.length < NUMBER_OF_MATCHES_IN_WEEK_DAY) {
    //     for(let i = 0; i < NUMBER_OF_MATCHES_IN_WEEK_DAY; i++) {
    //       if(!matchDayMatches[i]) {
    //         matchDayMatches[i] = { idx: i };
    //       }
    //     }
    //   }

    //   return {
    //     date: matchDay.date,
    //     matches: matchDayMatches,
    //   };
    // });
  }

  return [];
};

const demoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.DEMO_MATCHES_FETCHED:
      return formatMatches(action.payload);

    case TYPES.DEMO_MATCHES_SAVED:
      return INITIAL_STATE;

    case TYPES.DEMO_MATCHES_DELETED: {
      return [
        ...state.filter((day) => day.date !== action.payload),
      ];
    }

    default:
      return state;
  }
};

export default demoReducer;
