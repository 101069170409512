import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { removeAllWhitespaces } from '../../utils/formatters';
import {
  DEFAULT_FILTER_VALUE, DEFAULT_SEASON_FILTER_PLACEHOLDER,
  DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER, DEFAULT_CLUB_FILTER_PLACEHOLDER
} from './../../constants/filters';
import { fetchAreas, selectArea } from '../../store/actions/areas';
import { fetchClubs, selectClub, updateClub } from '../../store/actions/clubs';
import { setUrlFilters } from '../../store/actions/filters';
import { fetchSports } from '../../store/actions/sports';
import { fetchPackages, selectPackage } from './../../store/actions/packages';
import { fetchTournaments, selectTournament } from './../../store/actions/tournaments';
import storage from '../../utils/localStorage';
import SelectFilter from './SelectFilter';
import TreeFilter from './TreeFilter';

import './Filters.scss';
import { useSeasonsSlice } from '@/store/slices/seasons';

const Filters = ({ filtersList = [], search }) => {
  const { seasonsList, setSelectedSeasonByUUID, fetchSeasons, clearSeasonsData } = useSeasonsSlice();
  const [ clubsSelectPlaceholder, setClubsSelectPlaceholder ] = useState(DEFAULT_CLUB_FILTER_PLACEHOLDER);

  const filters = useSelector(({ filters }) => filters);
  const { area, season, package: packageItem, sport, tournament, club } = filters;

  const sports = useSelector(({ sports }) => sports);
  const areas = useSelector(({ areas }) => areas.tree);
  const tournaments = useSelector(({ tournaments }) => tournaments.list);
  const clubs = useSelector(({ clubs }) => clubs.list);
  const packages = useSelector(({ packages }) => packages.list);
  const [ packagesSelectPlaceholder, setPackagesSelectPlaceholder ] = useState();
  const dispatch = useDispatch();

  const hasFilter = useCallback((name) => filtersList && filtersList.includes(name), [filtersList]);
  const setUrlFilter = useCallback((filterName, filterObjList) => {
    const filtersFromUrl = queryString.parse(search);
    if (filtersFromUrl[filterName] && filterObjList.length > 0) {
      let newFilter;
      if (filterObjList[0].children) {
        newFilter = Object.values(filterObjList)
          .map((obj) => obj.children
            .find((country) => removeAllWhitespaces(country.name) === filtersFromUrl[filterName]))
          .find((el) => el !== undefined);
      } else {
        newFilter = filterObjList.find((obj) => removeAllWhitespaces(obj.name) === filtersFromUrl[filterName]);
      }
      newFilter && dispatch(setUrlFilters(filterName, newFilter));
    }
  }, [dispatch, search]);

  useEffect(() => {
    sports && setUrlFilter('sport', sports);
  }, [sports, setUrlFilter]);

  useEffect(() => {
    !area && areas && setUrlFilter('area', areas);
  }, [dispatch, area, areas, setUrlFilter]);

  useEffect(() => {
    !tournament && tournaments && setUrlFilter('tournament', tournaments);
  }, [dispatch, tournament, tournaments, setUrlFilter]);

  useEffect(
    () => { !season && seasonsList.length && setUrlFilter('season', seasonsList); },
    [season, seasonsList.length, setUrlFilter],
  );

  useEffect(() => {
    const storageFilters = JSON.parse(storage.getItem('filters')) || {};

    if (!search && Object.values(storageFilters).length > 0) {
      for (const filter in storageFilters) {
        if (!storageFilters.hasOwnProperty(filter)) continue;
        dispatch(setUrlFilters(filter, storageFilters[filter]));
      }
    }
  }, [dispatch, search]);

  useEffect(() => {
    !sports.length && dispatch(fetchSports());
  }, [
    dispatch,
    sports.length,
  ]);

  useEffect(() => {
    !areas.length && hasFilter('area') && dispatch(fetchAreas());
  }, [
    dispatch,
    areas.length,
    hasFilter,
  ]);

  useEffect(() => {
    if (sport?.name && area && hasFilter('tournament')) {
      dispatch(fetchTournaments(area.code, sport.name));
    }
  }, [
    dispatch,
    area,
    sport,
    hasFilter,
  ]);

  useEffect(() => {
    if (tournament && hasFilter('season')) {
      dispatch(selectTournament(tournament.id));
      fetchSeasons(tournament.id);
    } else {
      clearSeasonsData();
    }
  }, [
    dispatch,
    tournament,
    hasFilter,
  ]);

  useEffect(() => {
    if (area && area.id) {
      dispatch(selectArea(area.id));
    }
  }, [
    area,
    dispatch,
  ]);

  useEffect(
    () => { season?.id && setSelectedSeasonByUUID(season.id); },
    [season]
  )

  useEffect(() => {
    !club && clubs && setUrlFilter('club', clubs);
  }, [dispatch, club, clubs, setUrlFilter]);

  const tournamentsSelectPlaceholder = useMemo(
    () => {
      if (sport?.name && area?.name && hasFilter('tournament')) return DEFAULT_FILTER_VALUE;
      return tournament?.name || DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER;
    },
    [sport, area, tournament]
  );

  const seasonsSelectPlaceholder = useMemo(
    () => {
      if (!tournament?.name) return DEFAULT_SEASON_FILTER_PLACEHOLDER;
      return season?.name || DEFAULT_FILTER_VALUE;
    },
    [tournament, season]
  );

  useEffect(() => {
    if(club && club.id) {
      dispatch(selectClub(club.id));
      setClubsSelectPlaceholder(club.name);
    } else if(!club) {
      setClubsSelectPlaceholder(DEFAULT_CLUB_FILTER_PLACEHOLDER);
    }
  }, [
    club,
    dispatch,
  ]);

  useEffect(() => {
    if (area && area.id) {
      if(season && hasFilter('club')) {
        dispatch(fetchClubs({seasonId: season.id}));
        setClubsSelectPlaceholder(DEFAULT_FILTER_VALUE);
      } else {
        setClubsSelectPlaceholder(DEFAULT_CLUB_FILTER_PLACEHOLDER);
      }
  }}, [
    dispatch,
    area,
    season,
    hasFilter,
    setClubsSelectPlaceholder,
  ])

  useEffect(() => {
    !packageItem && packages && setUrlFilter('package', packages);
  }, [dispatch, packageItem, packages, setUrlFilter]);

  useEffect(
    () => {
      if(packageItem && packageItem.id) {
        dispatch(selectPackage(packageItem.id));
        setPackagesSelectPlaceholder(packageItem.name);
      } else {
        setPackagesSelectPlaceholder(DEFAULT_FILTER_VALUE);
      }
    },
    [packageItem, dispatch]
  );

  return (
    <section className="filters">
      <h3>Filters</h3>

      {hasFilter('sport') && sports && <SelectFilter name="sport" list={sports} placeholder={sport?.name} />}
      {hasFilter('area') && areas && <TreeFilter keepTreeExpandedAfterSelect={false} name="area" treeData={areas} placeholder={area?.name} />}
      {hasFilter('tournament') && tournaments && <TreeFilter name="tournament" treeData={tournaments} placeholder={tournamentsSelectPlaceholder} />}
      {hasFilter('season') && seasonsList && <TreeFilter name="season" treeData={seasonsList} placeholder={seasonsSelectPlaceholder} />}
      {hasFilter('club') && clubs && <SelectFilter name="club" list={clubs} placeholder={clubsSelectPlaceholder} />}
      {hasFilter('package') && packages && <SelectFilter name="package" list={packages} placeholder={packagesSelectPlaceholder} />}
    </section>
  );
};

Filters.propTypes = {
  filtersList: PropTypes.array.isRequired,
};

export default Filters;
