export const FIXTURE_TOOLTIPS = {
  n: 'Neutral',
  HT: 'Half time',
  FT: 'Full time',
  AET: 'After extra time',
  PEN: 'Penalties',
};

export const INCIDENTS_TOOLTIPS = {
  si: 'Substitution in',
  so: 'Substitution out',
  yc: 'Yellow card',
  rc: 'Red card',
  g: 'Goal',
  pg: 'Penalty goal',
  p: 'Penalty',
};

export const PREDICTIONS_TOOLTIPS = {
  summary: {
    H: 'Home win',
    D: 'Draw',
    A: 'Away win',
  },
  team: {
    TT: 'Tournament type: L(eague), C(up) or F(riendly)',
    HoT: 'Home Tournament: Most commonly; Domestic league of club teams or continental qualifiers of national teams',
    TL: 'Tournament Level: Of home tournament (HoT) at date of match',
    TLR: 'Tournament Level Ratio: Level of home tournament of team, to level of home tournament of opponent, both at date of previous result',
    TLRP: 'Tournament Level Ratio at Prediction: Level of home tournament of team at date of prediction, to level of home tournament of opponent at date of previous result',
    S: 'Number of Scored goals',
    C: 'Number of Conceded goals',
    HAN: 'Ground indicator: H(ome), A(way) or N(eutral)',
    SOGI: 'Same- to Other Ground Importance',
    TI: 'Tournament Importance: Of tournament of previous result',
    OS: 'Offensive Strength of opponent at date of previous result',
    DS: 'Defensive Strength of opponent at date of previous result',
    OTA: 'Offensive strength Average of Tournament (of previous result) at date of previous result',
    DTA: 'Defensive strength Average of Tournament (of previous result) at date of previous result',
    OSM: 'Offensive Strength Measured/calculated, of team, from values given by previous result',
    'OSM%': 'Offensive Strength Measured/calculated, of team, as percentage of the total measure',
    DSM: 'Defensive Strength Measured/calculated, of team, from values given by previous result',
    'DSM%': 'Defensive Strength Measured/calculated, of team, as percentage of the total measure',
  },
};
