import * as TYPES from '../actionTypes';

const INITIAL_STATE = {};

const predictionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.PREDICTIONS_FETCHED:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default predictionsReducer;
