import { combineReducers } from 'redux';

import areasReducer from './areasReducer';
import clubsReducer from './clubsReducer';
import demoReducer from './demoReducer';
import filtersReducer from './filtersReducer';
import packagesReducer from './packagesReducer';
import predictionsReducer from './predictionsReducer';
import predictionsSeasonReducer from './predictionsSeasonReducer';
import saveBarReducer from './saveBarReducer';
import sportsReducer from './sportsReducer';
import tournamentsReducer from './tournamentsReducer';
import tournamentsRankingReducer from './tournamentsRankingReducer';
import oddsenReducer from './oddsenReducer';
import playersReducer from './playersReducer';

import seasonsReducer from '@/store/slices/seasons';
import widgetsReducer from '@/store/slices/widgets';

const appReducer = combineReducers({
  areas: areasReducer,
  clubs: clubsReducer,
  demo: demoReducer,
  demoOddsen: oddsenReducer,
  filters: filtersReducer,
  packages: packagesReducer,
  predictions: predictionsReducer,
  predictionsSeason: predictionsSeasonReducer,
  saveBar: saveBarReducer,
  seasons: seasonsReducer,
  sports: sportsReducer,
  tournaments: tournamentsReducer,
  tournamentsRanking: tournamentsRankingReducer,
  widgets: widgetsReducer,
  players: playersReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
