/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Form, Input } from 'antd';
import classnames from 'classnames';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);


const EditableTableCell = props => {
  const inputElement = useRef(null);
  const [ editing, setEditing ] = useState(false);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if(editing) {
      inputElement.current.focus();
    }
  }, [editing]);

  const save = (e, form) => {
    const { record, handleSave } = props;

    form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  const renderCell = form => {
    const { children, dataIndex, record } = props;

    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          initialValue: record[dataIndex],
        })(<Input ref={inputElement} onPressEnter={e => save(e, form)} onBlur={e => save(e, form)} />)}
      </Form.Item>
    ) : (
      <div
        className={classnames(
          'editable-cell-value-wrap',
          { disabled: !editableInput }
        )}
        onClick={editableInput ? toggleEdit : () => {}}
      >
        {children}
      </div>
    );
  };

  const {
    editable,
    editableInput,
    dataIndex,
    title,
    record,
    index,
    handleSave,
    children,
    ...restProps
  } = props;

  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

export { EditableTableCell, EditableFormRow };
