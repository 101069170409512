import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { Button, Icon, Menu } from 'antd';

import { DESKTOP_WIDTH } from '../../constants';
import { MENU } from '../../constants/menu';

import logo from '../../assets/images/logo.png';

import './Header.scss';

const Header = ({ isMobile, onLogout }) => {
  const navigationDisabled = useSelector(({ saveBar }) => saveBar.visible);
  const { location } = useHistory();
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const isMobileView = isMobile || window.innerWidth < DESKTOP_WIDTH;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderMenuItems = () => MENU.map((item) => (
    <Menu.SubMenu key={item.name} title={item.name}>
      {item.submenus && item.submenus.map((subitem) => (
        <Menu.Item key={`/${subitem.target}`}>
          <Link
            className={classnames({
              disabled: navigationDisabled,
            })}
            onClick={(e) => (navigationDisabled ? e.preventDefault() : {})}
            to={`${subitem.target}${location.search}`}
          >
            <span>{subitem.name}</span>
          </Link>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  ));

  return (
    <div className="header">
      <div className="logo-container">
        <img src={logo} className="logo" alt="logo" />
      </div>

      {isMobileView
        && (
        <Button
          className="burger"
          type="primary"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <Icon type={isCollapsed ? 'menu-unfold' : 'menu-fold'} />
        </Button>
        )}

      {(isCollapsed || !isMobileView) && (
        <Menu
          onClick={(e) => setCurrentPage(e.key)}
          selectedKeys={[currentPage]}
          mode={isMobileView ? 'inline' : 'horizontal'}
        >
          {renderMenuItems()}
          <Menu.Item className="logout-btn">
            <Button onClick={onLogout}>Log out</Button>
          </Menu.Item>
        </Menu>
      )}
    </div>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
};

export default Header;
