import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Upload, Icon, message, Popconfirm,
} from 'antd';

import { UPLOADER_FILES_SIZE, UPLOADER_ACTION_URL } from '../../constants';
import { api } from '../../utils/api';
import { addImage, deleteImage } from '../../store/actions/uploader';

import './Uploader.scss';

const { Dragger } = Upload;

const Uploader = ({ data, id, uploaderEnabled }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [uploaderConfig, setUploaderConfig] = useState(null);

  const additionalData = {
    uuid: data.id,
  };

  const removeFile = () => {
    if (data.badgeUrl) {
      const fileName = data.badgeUrl.split('/').pop();

      dispatch(deleteImage(fileName, data.contextType));
    }
  };

  useEffect(() => {
    const getConfig = async() => {
      const token = await api.getToken();
      const config = {
        disabled: !uploaderEnabled,
        name: 'file',
        multiple: false,
        action: `${UPLOADER_ACTION_URL}/${(data.contextType || 'team')}`,
        accept: '.png,.jpg',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: additionalData,
        onChange(info) {
          const { status } = info.file;

          if (status === 'uploading') {
            setLoading(true);
          } else if (status === 'done') {
            message.success('File uploaded successfully.');
            setLoading(false);
            dispatch(addImage(info.file.response.url, data.contextType, data.id));
          } else if (status === 'error') {
            setLoading(false);
            message.error('File upload failed.');
          }
        },
      };

      setUploaderConfig(config);
    }
    getConfig();
  }, [uploaderEnabled, data.contextType, data.id])

  const renderUploaderImage = () => {
    if (loading) {
      return <Icon type="loading" />;
    }

    if (data.badgeUrl) {
      const now = new Date();
      const imageSource = `${data.badgeUrl}?${now.getTime()}`;
      return <img src={imageSource} alt="uploaded" />;
    }

    return (
      <>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </>
    );
  };

  if (!uploaderConfig) return null;

  return (
    <div className="uploader-container">
      <Dragger className={`file-size-${id}`} {...uploaderConfig} showUploadList={false}>
        {renderUploaderImage()}
        <p className="file-decription">
          {UPLOADER_FILES_SIZE[id - 1]}
          {' '}
          x
          {' '}
          {UPLOADER_FILES_SIZE[id - 1]}
          {' '}
          .png .jpg
        </p>
      </Dragger>
      <div className="btns-container">
        {uploaderEnabled ? (
          <Upload {...uploaderConfig} className="uploader" showUploadList={false}>
            <Button type="primary">
              <Icon type="upload" />
              Upload
            </Button>
          </Upload>
        ) : null}

        {uploaderEnabled ? (
          <Popconfirm
            title="Are you sure you want to remove this file?"
            onConfirm={removeFile}
          >
            <Button type="danger" className="remove-btn">
              Remove
            </Button>
          </Popconfirm>
        ) : null}
      </div>
    </div>
  );
};

export default Uploader;
