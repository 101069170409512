import * as TYPES from '../actionTypes';
import { SAVE_BAR_STATUS } from '../../constants/saveBar';

const INITIAL_STATE = {
  onReset: () => {},
  onSave: () => {},
  visible: false,
  status: SAVE_BAR_STATUS.HIDE,
};

const saveBarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.SAVE_BAR_SHOW:
      const onSave = action.payload;

      return {
        ...state,
        onSave,
        visible: true,
      };

    case TYPES.SAVE_BAR_SET_RESET:
      const onReset = action.payload;

      return {
        ...state,
        onReset,
      };

    case TYPES.SAVE_BAR_HIDE:
      const newStatus = action.payload;

      return {
        ...state,
        visible: false,
        status: newStatus,
      };

    default:
      return state;
  }
};

export default saveBarReducer;
