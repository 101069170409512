export const FILTERS = {
  configuration: ['sport', 'area'],
  prediction: ['sport', 'area', 'tournament', 'season'],
  setup: ['sport'],
  tournaments: ['sport', 'area', 'tournament', 'season'],
  'tournaments-ranking': ['sport'],
  packages: ['package'],
  analytics: ['sport', 'area', 'tournament', 'season'],
  demo: ['sport', 'area', 'tournament', 'season'],
  players: ['sport', 'area', 'tournament', 'season'],
};

export const DEFAULT_FILTER_VALUE = '---';
export const DEFAULT_SEASON_FILTER_PLACEHOLDER = 'Select tournament first';
export const DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER = 'Select area first';
export const DEFAULT_CLUB_FILTER_PLACEHOLDER = 'Select season first';

export const PATH_FOR_SHOWING_TREE_COUNTERS = '/mapping';