import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Popconfirm } from 'antd';

import { DEFAULT_FILTER_VALUE, DEFAULT_SEASON_FILTER_PLACEHOLDER, DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER } from '../../constants/filters';
import { fetchTournaments } from '../../store/actions/tournaments';
// import { fetchSeasons } from './../../store/actions/seasons';
import { fetchAreas, selectArea } from '../../store/actions/areas';
import TreeFilter from '../../components/Filters/TreeFilter';

import './Packages.scss';

const PackageDetails = ({ seasonsList = [], setSeasonsList }) => {
  const filters = useSelector(({ filters }) => filters);
  const areas = useSelector(({ areas }) => areas.tree);
  // const seasons = useSelector(({ seasons }) => seasons.list);
  const tournaments = useSelector(({ tournaments }) => tournaments.list);
  const {
    area, season, tournament, sport,
  } = filters;
  const dispatch = useDispatch();

  const [tournamentsInPackage, setTournamentsInPackage] = useState(seasonsList);
  const [areasSelectPlaceholder, setAreasSelectPlaceholder] = useState();
  const [seasonsSelectPlaceholder, setSeasonsSelectPlaceholder] = useState(DEFAULT_SEASON_FILTER_PLACEHOLDER);
  const [tournamentsSelectPlaceholder, setTournamentsSelectPlaceholder] = useState(DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER);

  useEffect(() => {
    if (filters.tournament && filters.tournament.id) {
      // dispatch(fetchSeasons(filters.tournament.id));
      setSeasonsSelectPlaceholder(DEFAULT_FILTER_VALUE);
    }
  }, [
    dispatch,
    filters.tournament,
  ]);

  useEffect(() => {
    if (sport?.name && area) {
      dispatch(fetchTournaments(area.code, sport.name));
      setTournamentsSelectPlaceholder(DEFAULT_FILTER_VALUE);
    } else {
      setTournamentsSelectPlaceholder(DEFAULT_TOURNAMENT_FILTER_PLACEHOLDER);
    }
    setSeasonsSelectPlaceholder(DEFAULT_SEASON_FILTER_PLACEHOLDER);
  }, [
    dispatch,
    area,
    sport,
  ]);

  useEffect(() => {
    !areas.length && dispatch(fetchAreas());
  }, [
    dispatch,
    areas.length,
  ]);

  useEffect(() => {
    if (area && area.id) {
      dispatch(selectArea(area.id));
      setAreasSelectPlaceholder(area.name);
    }
  }, [
    area,
    dispatch,
  ]);

  useEffect(() => {
    if (season && season.id) {
      setSeasonsSelectPlaceholder(season.name);
    }
  }, [
    season,
  ]);

  useEffect(() => {
    if (tournament && tournament.id) {
      setTournamentsSelectPlaceholder(tournament.name);
    }
  }, [
    tournament,
  ]);

  useEffect(() => {
    setTournamentsInPackage(seasonsList);
  }, [
    seasonsList,
    seasonsList.length,
  ]);

  const addSeasonToPackage = () => {
    const { tournament, season } = filters;

    const seasonInPackage = tournamentsInPackage.find((tournament) => tournament.season_id === season.id);

    if (!seasonInPackage) {
      tournamentsInPackage.push({
        season_id: season.id,
        season_name: season.name,
        season_year: season.year,
        tournament_id: tournament.id,
        tournament_name: tournament.name,
        tournament_abbrev: tournament.abbrev,
      });

      setTournamentsInPackage([...tournamentsInPackage]);
      setSeasonsList([...tournamentsInPackage]);
    }
  };

  const deleteTournament = (tournamentToDelete) => {
    const updatedTournaments = tournamentsInPackage.filter((tournament) => tournament.season_id === tournamentToDelete.season_id);
    setTournamentsInPackage([...updatedTournaments]);
  };

  const areFiltersSelected = () => {
    const {
      sport, area, tournament, season,
    } = filters;

    return !!sport && !!area && !!tournament && !!season;
  };

  const renderPackagesList = () => tournamentsInPackage && tournamentsInPackage.map((tournament, idx) => (
    <div key={tournament.season_id + idx}>
      <span>{tournament.season_name}</span>

      <Popconfirm
        title="Are you sure to delete season from a package?"
        onConfirm={() => deleteTournament(tournament)}
        onCancel={() => {}}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" title="Delete the season" />
      </Popconfirm>
    </div>
  ));

  return (
    <div className="packages">
      <div className="packages-attributes-section">
        <aside>
          <div className="filters">
            <h3>Select tournament/season</h3>

            {areas && <TreeFilter name="area" treeData={areas} placeholder={areasSelectPlaceholder} />}
            {tournaments && <TreeFilter name="tournament" treeData={tournaments} placeholder={tournamentsSelectPlaceholder} />}
            {/* {seasons && <TreeFilter name="season" treeData={seasons} placeholder={seasonsSelectPlaceholder} />} */}

            <div className="button-container">
              <Button type="primary" disabled={!areFiltersSelected()} onClick={addSeasonToPackage}>
                Add season to package
              </Button>
            </div>
          </div>
        </aside>
        <div className="list-area">
          {renderPackagesList()}
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
