import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { loadProgressBar } from 'axios-progress-bar';
import { ToastContainer } from 'react-toastify';

import { store } from './store';
import Routes from './Routes'

import 'antd/dist/antd.css';
import './styles/app.scss';
import 'axios-progress-bar/dist/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '@/components';
import { api } from '@/utils/api';


const App = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    loadProgressBar();
  }, []);

  useEffect(() => {
    api.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <Loading resources="App" />;
  }

  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer />
    </Provider>
  );
};

export default App;
