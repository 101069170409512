import * as TYPES from '../actionTypes';
import { NUMBER_OF_ODDSEN_IN_WEEK_DAY } from '../../constants/demo';

const INITIAL_STATE = [];

const formatMatches = (payload) => {
  if (payload && payload.length) {
    return payload.map((matchDay) => {
      const matchDayMatches = matchDay.matches
        ? matchDay.matches.map((match, idx) => ({
          idx,
          id: match.id,
          home: match.home,
          away: match.away,
          areaId: match.area && match.area.id,
          tournamentId: match.tournament && match.tournament.id,
          seasonId: match.season && match.season.id,
        }))
        : [{ idx: 1 }, { idx: 2 }, { idx: 3 }, { idx: 4 }, { idx: 5 }];

      if (matchDayMatches.length < NUMBER_OF_ODDSEN_IN_WEEK_DAY) {
        for (let i = 0; i < NUMBER_OF_ODDSEN_IN_WEEK_DAY; i++) {
          if (!matchDayMatches[i]) {
            matchDayMatches[i] = { idx: i };
          }
        }
      }

      return {
        date: matchDay.date,
        matches: matchDayMatches,
      };
    });
  }

  return [];
};

const oddsenReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.DEMO_ODDSEN_FETCHED:
      return formatMatches(action.payload);

    case TYPES.DEMO_ODDSEN_SAVED:
      return INITIAL_STATE;

    case TYPES.DEMO_ODDSEN_DELETED: {
      return [
        ...state.filter((day) => day.date !== action.payload),
      ];
    }

    default:
      return state;
  }
};

export default oddsenReducer;
