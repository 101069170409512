import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { Form, SaveBar } from '../../components';
import { saveSport } from '../../store/actions/sports';
import {
  nameFormData, scoreFormData, predictionsFormData,
} from './Forms';
import { replaceFormVariables } from '../../utils/forms';

import './Setup.scss';

const Setup = () => {
  const sports = useSelector(({ sports }) => sports);
  const sport = useSelector(({ filters }) => filters.sport);
  const dispatch = useDispatch();

  const [editedSport, setEditedSport] = useState({});
  const [sportsNameFormData, setSportsNameFormData] = useState(null);
  const [sportsScoreFormData, setSportsScoreFormData] = useState(null);
  const [sportsPredictionFormData, setSportsPredictionFormData] = useState(null);
  const [editedFormsData, setEditedFormsData] = useState({});

  const setOriginalFormData = useCallback(() => {
    const selectedSport = sports.find((sp) => sp.uuid === sport.uuid);

    if (sport && selectedSport) {
      setEditedSport({ uuid: selectedSport.uuid });

      const nameForm = replaceFormVariables(nameFormData, selectedSport);
      setSportsNameFormData(nameForm);

      const scoreForm = replaceFormVariables(scoreFormData, selectedSport);
      setSportsScoreFormData(scoreForm);

      if (selectedSport) {
        const predictionForm = replaceFormVariables(predictionsFormData, selectedSport);
        setSportsPredictionFormData(predictionForm);
      }
    }
  }, [
    sport,
    sports,
  ]);

  useEffect(() => {
    setOriginalFormData();
  }, [
    sport,
    sports,
    setOriginalFormData,
  ]);

  const onSetupSave = () => {
    const {
      score, groundImportance, levels, uuid,
    } = editedFormsData;

    dispatch(saveSport({
      uuid, ...score, ...groundImportance, ...levels,
    }));
    setEditedFormsData({});
  };

  const handleFormChange = (data) => {
    setEditedFormsData({
      ...editedFormsData,
      ...data,
    });
  };

  return useMemo(() => (sport && sport.uuid ? (
    <div className="setup-container">
      <SaveBar
        onSave={onSetupSave}
        disabled={!Object.entries(editedFormsData).length}
      />
      <div className="page-title">{sport.name}</div>

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Basics" key="1">
          <div className="forms-inline-container">
            {sportsNameFormData && (
            <Form
              editData={editedSport}
              form={sportsNameFormData}
              handleFormChange={handleFormChange}
            />
            )}
            {sportsScoreFormData && (
            <Form
              editData={editedSport}
              form={sportsScoreFormData}
              handleFormChange={handleFormChange}
            />
            )}
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Prediction" key="2">
          <div className="forms-inline-container">
            {sportsPredictionFormData && (
            <Form
              editData={editedSport}
              form={sportsPredictionFormData}
              handleFormChange={handleFormChange}
            />
            )}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  ) : (
    <div>Please select sport</div>
  )), [
    editedSport,
    editedFormsData,
    sport,
    sportsNameFormData,
    sportsScoreFormData,
    sportsPredictionFormData,
  ]);
};

export default Setup;
