import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';

import { DEFAULT_FILTER_VALUE } from '../../constants/filters';
import storage from '../../utils/localStorage';
import { setFilters, clearFilters } from '../../store/actions/filters';
import TreeView from '../TreeView';
import FilterHeader from './FilterHeader';

import './Filters.scss';

const TreeFilter = ({ name = '', treeData = [], placeholder = null, keepTreeExpandedAfterSelect = true }) => {
  const filters = useSelector(({ filters }) => filters);
  const dispatch = useDispatch();

  const storageFilters = JSON.parse(storage.getItem('filters')) || {};
  const [selectedValue, setSelectedValue] = useState(placeholder || DEFAULT_FILTER_VALUE);
  const [treeVisibility, setTreeVisibility] = useState(!keepTreeExpandedAfterSelect);
  const [treeViewData, setTreeViewData] = useState(treeData);

  useEffect(
    () => {
      const newSelectedValue = (storageFilters?.[name] || filters?.[name])?.name;
      newSelectedValue && setSelectedValue(newSelectedValue);
    },
    [filters, name]
  )

  useEffect(() => {
    setTreeViewData(treeData);
  }, [treeData]);

  useEffect(() => {
    if (placeholder) {
      setSelectedValue(placeholder);
    }
  }, [placeholder]);

  const handleClearFilters = useCallback(() => {
    dispatch(clearFilters(name));
    setSelectedValue(DEFAULT_FILTER_VALUE);
    setTreeVisibility(false);
  }, [
    dispatch,
    name,
  ]);

  const setSelectedItem = (item) => ({ id: item.id, name: item.name, code: item.code || null });

  const getSelectedValue = (key) => {
    let selected = {};
    treeData.forEach((item) => {
      if (item.name.toLowerCase() === key.toLowerCase()) {
        selected = setSelectedItem(item);
      }

      if (item.children) {
        item.children.forEach((child) => {
          if (child.name.toLowerCase() === key.toLowerCase()) {
            selected = setSelectedItem(child);
          }
        });
      }
    });

    return selected;
  };

  const handleSelect = (keys) => {
    if (keys && keys.length) {
      const key = keys[0];
      const selected = getSelectedValue(key);

      setSelectedValue(key);
      dispatch(setFilters(name, selected));
      keepTreeExpandedAfterSelect && setTreeVisibility(false);
    }
  };

  const toggleTreeVisibility = () => {
    setTreeVisibility(!treeVisibility);
  };

  return (
    <div className="filters-row">
      <FilterHeader
        handleClearFilters={handleClearFilters}
        name={name}
      />

      <div
        onClick={toggleTreeVisibility}
        className="filter-tree-selector"
      >
        {selectedValue}
        <Icon type={treeVisibility ? 'up' : 'down'} />
      </div>
      {treeVisibility && treeData && (
        <TreeView
          keepTreeExpandedAfterSelect
          data={treeViewData}
          handleSelect={handleSelect}
          setTreeFilterData={setTreeViewData}
          selectedTopValue={selectedValue}
        />
      )}
    </div>
  );
};

TreeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  treeData: PropTypes.array.isRequired,
};

export default TreeFilter;
