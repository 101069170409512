import * as TYPES from '../actionTypes';
import { sortByKey } from '../../utils/objects';

const INITIAL_STATE = {
  list: [],
  selected: {},
};

const tournamentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.TOURNAMENTS_FETCHED:
      return {
        ...state,
        list: sortByKey(action.payload, 'name'),
      };

    case TYPES.TOURNAMENTS_CLEAR:
      return {
        ...state,
        list: [],
        selected: INITIAL_STATE.selected,
      };

    case TYPES.TOURNAMENT_SELECT:
      return {
        ...state,
        selected: {
          ...state.list.find((tournament) => tournament.id === action.payload),
        },
      };

    case TYPES.TOURNAMENT_UPDATED: {
      return {
        ...state,
        selected: {
          ...state.selected,
          leagueLevel: action.payload.leagueLevel,
          predictionLevel: action.payload.predictionLevel,
          predictionImportance: action.payload.predictionImportance,
        },
      };
    }

    case TYPES.TOURNAMENT_FIXTURES_FETCHED:
      return {
        ...state,
        selected: {
          ...state.selected,
          fixtures: action.payload.fixtures,
        },
      };

    case TYPES.IMAGE_TOURNAMENT_ADDED:
      return {
        ...state,
        selected: {
          ...state.selected,
          badgeUrl: action.payload,
        },
      };

    case TYPES.IMAGE_TOURNAMENT_DELETED:
      delete state.selected.badgeUrl;

      return {
        ...state,
        selected: {
          ...state.selected,
        },
      };

    default:
      return state;
  }
};

export default tournamentsReducer;
