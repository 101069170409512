export const UPLOAD_MENU = [
  {
    id: 1,
    menuItemName: 'Standard',
  },
  {
    id: 2,
    menuItemName: 'Rounded icon',
  },
  {
    id: 3,
    menuItemName: 'Tiny icon',
  },
];
