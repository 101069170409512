import { api } from '../../utils/api';
import * as TYPES from '../actionTypes';
import { convertObjectKeysToUnderscore } from '../../utils/formatters';

export const fetchSports = () => async (dispatch) => {
  try {
    const { data } = await api.get('/sports');

    dispatch({ type: TYPES.SPORTS_FETCHED, payload: data });
  } catch (error) {
    error.handleGlobally();
  }
};

export const saveSport = (sportData) => async (dispatch) => {
  try {
    const { status } = await api.put(`/sports/${sportData.uuid}`, convertObjectKeysToUnderscore(sportData));

    if (status === 200) {
      dispatch(fetchSports());
    }

    dispatch({ type: TYPES.SAVE_BAR_HIDE });
  } catch (error) {
    error.handleGlobally();
  }
};
