import L from 'lodash';
import FP from 'lodash/fp';
import moment from 'moment';

import { WidgetsInfoRecords } from "@/store/slices/widgets";
import { UUID } from "@/types/common";
import { DAYS_UPFRONT } from '@/constants/demo';
import { WidgetFixtureFilterData } from '@/views/Demo/Demo.types';

export const initRecordsDraft = (numOfRows: number): Record<UUID, Partial<WidgetFixtureFilterData>> => {
  const dateKeys = L.map(
    Array(DAYS_UPFRONT + 1),
    (_, idx) => (
      moment(new Date())
        .utcOffset(0)
        .startOf('day')
        .add(idx, 'day')
        .toISOString()
    )
  ) as UUID[];
  
  const fillArray = (size: number, value = undefined) => (
    L.fill(Array(size), value)
  );
  
  return L.zipObject(
    dateKeys,
    fillArray(L.size(dateKeys), fillArray(numOfRows))
  );
}
  
export const syncDraftWithPayload = (draft: WidgetsInfoRecords, payload: WidgetsInfoRecords): WidgetsInfoRecords => L.flow(
  L.toPairs,
  FP.map(
    ([dateISO, uuidArray]) => [
      dateISO,
      L.zipWith(
        payload[dateISO],
        uuidArray,
        L.defaultTo
      )
    ]
  ),
  L.fromPairs
)(draft);