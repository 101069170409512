import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';

import { fetchPlayers } from '../../store/actions/players';
import { DEFAULT_PLAYERS_ATTRIBUTES } from '../../constants/players';
import { createSingleFormField } from '../../utils/forms';

import PlayersListSection from './components/PlayersListSection';
import NewPlayerForm from './FormsComponents/NewPlayerForm'
import PlayerAttributes from './PlayerAttributes';
import './Players.scss';

const Players = ({ location }) => {
  const filters = useSelector(({ filters }) => filters);
  const players = useSelector(({ players }) => players);
  const dispatch = useDispatch();

  const [ playerAttributes, setPlayerAttributes ] = useState(null);
  const [ editedFormsData, setEditedFormsData ] = useState({});

  useEffect(() => {
    if(filters.season && filters.season.id) {
      dispatch(fetchPlayers({seasonUuid: filters.season.id}));
    }
  }, [
    dispatch,
    filters.season,
    filters.club,
  ]);

  useEffect(() => {
    if(players.selected && players.selected.uuid) {
      const attributes = {
        ...DEFAULT_PLAYERS_ATTRIBUTES,
      };
      attributes.sections[0].rows = [];
      attributes.sections[0].rows.push(
        createSingleFormField('Full Name', { type: 'text', name: 'name', value: players.selected.name }, false),
        createSingleFormField('Short Name', { type: 'text', name: 'shortName', value: players.selected.shortName }, false),
        createSingleFormField('Nickname', { type: 'text', name: 'nickname', value: players.selected.nickname, size: 'small' }, false),
        createSingleFormField('Position', { type: 'text', name: 'position', value: players.selected.position, size: 'small' }, false),
        createSingleFormField('Shirt Number', { type: 'number', name: 'shirtNr', value: players.selected.shirtNr, size: 'small' }, false),
        createSingleFormField('Birthdate', { type: 'date-picker', name: 'birthdate', value: players.selected.birthdate }, false),
        createSingleFormField('Weight', { type: 'decimal', name: 'weight', value: players.selected.weight, size: 'small' }, false),
        createSingleFormField('Height', { type: 'decimal', name: 'height', value: players.selected.height, size: 'small' }, false),
        createSingleFormField('Place of birth', { type: 'text', name: 'placeOfBirth', value: players.selected.placeOfBirth, size: 'small' }, false),
        createSingleFormField('Retired', { type: 'text', name: 'isRetired', value: players.selected.isRetired, size: 'small' }, false),
      );
      players.selected = {
        ...players.selected,
        ...attributes,
      };
      setPlayerAttributes(players.selected);
    }
  }, [
    players,
  ]);

  const arePlayersAvailable = useMemo(
    () => players && players.list && players.list.length,
    [players],
  );

  return (
    <Fragment>
      <PlayersListSection location={location}>
        <Tabs
          defaultActiveKey="1"
        >
          <Tabs.TabPane tab="Player basic data" key="1">
            {arePlayersAvailable ? (
              <PlayerAttributes
                location={location}
                playerAttributes={playerAttributes}
                setEditedFormsData={setEditedFormsData}
                editedFormsData={editedFormsData}
              />
            ) : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Create new player form" key="2">
            <div style={{height: "800px"}}>
              <NewPlayerForm />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </PlayersListSection>
    </Fragment>
  )
    
};

export default Players;