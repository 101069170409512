import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tabs } from 'antd';

import { fetchSeasonsPredictions, clearSeasonsPredictions } from '../../store/actions/predicitons';

import AnalyticsSeason from './AnalyticsSeason';
import AnalyticsGoals from './AnalyticsGoals';
import AnalyticsMatches from './AnalyticsMatches';

import './Analytics.scss';

const Analytics = () => {
  const predictionsSeason = useSelector(({ predictionsSeason }) => predictionsSeason);
  const filters = useSelector(({ filters }) => filters);
  const dispatch = useDispatch();
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    const { season } = filters;
    if (season) {
      dispatch(clearSeasonsPredictions());
      setButtonEnabled(true);
    }
  }, [
    filters,
    filters.season,
    dispatch,
  ]);

  useEffect(() => {
    if (predictionsSeason && predictionsSeason.matrix && predictionsSeason.matrix.length) {
      setButtonEnabled(true);
    }
  }, [
    predictionsSeason,
  ]);

  const handleClickGenerate = useCallback(() => {
    const { season } = filters;

    if (season) {
      dispatch(fetchSeasonsPredictions(season));
    }
  }, [
    dispatch,
    filters,
  ]);

  return useMemo(() => (filters.season ? (
    <section className="analytics-section">
      <h1 className="season-name">{filters.season.name}</h1>
      <div className="inline-form">
        <Button
          type="primary"
          onClick={handleClickGenerate}
          disabled={!buttonEnabled}
        >
          Generate
        </Button>
      </div>

      {predictionsSeason && ((predictionsSeason.matrix && predictionsSeason.matrix.length > 0) || (predictionsSeason.matrixGoals && predictionsSeason.matrixGoals.length > 0)) && (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Season" key="1">
          {predictionsSeason.matrix && predictionsSeason.matrix.length > 0 ? (
            <AnalyticsSeason predictionsSeason={predictionsSeason.matrix} />
          ) : (
            <div>No data to show</div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Goals" key="2">
          {predictionsSeason.matrixGoals && predictionsSeason.matrixGoals.length > 0 ? (
            <AnalyticsGoals predictionsGoals={predictionsSeason.matrixGoals} />
          ) : (
            <div>No data to show</div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Matches" key="3">
          <AnalyticsMatches predictionsSeason={predictionsSeason.matches} />
        </Tabs.TabPane>
      </Tabs>
      )}
    </section>
  ) : (
    <div>Please, select area and season</div>
  )), [
    filters,
    filters.season,
    buttonEnabled,
    predictionsSeason,
  ]);
};

export default Analytics;
