import connector from '@/clients/DataProduct/connector';
import { Competition } from './types';

export interface GetCompetitionsParams {
  areaArea_code: string;
  sportName: string;
}

const tempResponseConverter = (data: Competition[]) => data.map(
  competition => ({
    ...competition,
    id: competition.uuid,
    contextType: 'competition'
  })
);

export const getCompetitions = (params: Partial<GetCompetitionsParams>) => connector<ReturnType<typeof tempResponseConverter>>({
  url: '/environments/competitions',
  method: 'GET',
  params,
  transformResponse: tempResponseConverter
});