import axios, { AxiosRequestConfig } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import { unary, partialRight } from 'lodash';

const connector = <T = unknown>({ transformResponse = [], ...restParams }: AxiosRequestConfig) => {
  return axios.request<T>({
    baseURL: window.__RUNTIME_CONFIG__.DATA_PRODUCT_API_BASE_URL,
    responseType: 'json',
    withCredentials: false,
    transformResponse: [
      unary(
        partialRight(camelcaseKeys, { deep: true })
      ),
      ...[transformResponse].flat(),
    ],
    ...restParams,
  });
};

export default connector;
