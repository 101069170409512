import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Input } from 'antd';
import { Loading } from '@/components';

import { selectPlayer } from '@/store/actions/players';


const PlayersListWithSearchBar = ({ location }) => {
  const players = useSelector(({ players }) => players);
  const playersList = useMemo(
    () =>  players.list,
    [players.list.length],
  );
  const selectedPlayer = useMemo(
    () => players.selected,
    [players.selected]
  )

  const [ searchResult, setSearchResult ] = useState([]);
  const [ searchFieldText, setSearchFieldText ] = useState('');
  const dispatch = useDispatch();

  const getPlayerFromUrl = useCallback(() => {
    const params = queryString.parse(location.search);
    return (params && params.searchPlayer) || null;
  }, [location.search]);

  useEffect(() => {
    const searchedPlayer = getPlayerFromUrl();
    if(searchedPlayer) {
      setSearchFieldText(searchedPlayer);
    }
  }, [getPlayerFromUrl]);

  useEffect(() => {
    let searchResults = playersList;
    const searchedPlayer = getPlayerFromUrl();
    if(searchedPlayer) {
      searchResults = players?.list.filter(player => player.name.includes(searchedPlayer));
    }
    setSearchResult(searchResults);
  }, [
    playersList,
    getPlayerFromUrl,
  ]);

  const handleSearch = useCallback(e => {
    const { value } = e.target;
    if(playersList) {
      if(!value) {
        setSearchResult(playersList);
      } else {
        if(value.length > 2) {
          const result = playersList.filter(player => player.name.toLowerCase().includes(value.toLowerCase()));
          setSearchResult(result);
        }
      }
    }
    setSearchFieldText(value);
  }, [
    playersList,
  ]);

  const handlePlayerSelect = player => {
    dispatch(selectPlayer(player.uuid));
  };

  return searchResult ? (
    <>
      <Input onChange={handleSearch} value={searchFieldText} placeholder="Search player" />
      <ul className="tree-view">
        {searchResult?.map(player =>(
          <li 
            key={player.uuid}
            className={selectedPlayer.uuid === player.uuid ? 'checked': ''}
            onClick={() => handlePlayerSelect(player)}
          >
            {player?.name}
          </li>
        ))}
      </ul>
    </>
  ) : (
    <Loading resources="players" />
  )
}

export default PlayersListWithSearchBar;
