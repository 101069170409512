import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import L from 'lodash';

import { getSeasonRounds, getSeasons } from '@/clients/DataProduct/environments/seasons';
import { RoundInfo } from '@/clients/DataProduct/environments/types';
import { Awaited, UUID } from '@/types/common';
import { createAsyncThunkWithErrorComposer } from '@/utils/errorComposer';
import { useBasicSlice } from '@/utils/store';

type Season = Awaited<ReturnType<typeof getSeasons>>['data'][number];

export interface SeasonsState {
  seasonsList: Season[];
  selectedSeason: Season;
  roundsList: RoundInfo[];
  selectedRound: RoundInfo;
}

const initialState: SeasonsState = {
  seasonsList: [],
  selectedSeason: {} as Season,
  roundsList: [],
  selectedRound: {} as RoundInfo
}

const fetchSeasons = createAsyncThunkWithErrorComposer(
  '/environments/seasons',
  async (competitionUuid: UUID) => {
    const { data } = await getSeasons({ competitionUuid });
    return data;
  }
);

const fetchRounds = createAsyncThunkWithErrorComposer(
  '/environments/seasons/rounds',
  async (seasonUuid: UUID) => {
    const { data } = await getSeasonRounds({ seasonUuid });
    return data;
  }
);

const seasonsSlice = createSlice({
  name: 'seasons',
  initialState,
  reducers: {
    setSelectedSeason: (state, { payload }: PayloadAction<Season>) => {
      state.selectedSeason = payload;
    },
    setSelectedSeasonByUUID: (state, { payload: uuid }: PayloadAction<UUID>) => {
      const selectedSeason = L.find(state.seasonsList, ['id', uuid]);
      if (selectedSeason) state.selectedSeason = selectedSeason;
    },
    setSelectedRound: (state, { payload }: PayloadAction<RoundInfo>) => {
      state.selectedRound = payload;
    },
    clearSeasonsData: (state) => {
      state.seasonsList = initialState.seasonsList;
      state.selectedSeason = initialState.selectedSeason;
    },
    clearRoundsData: (state) => {
      state.roundsList = initialState.roundsList;
      state.selectedRound = initialState.selectedRound;
    },
    clearSeasonsSlice: (state) => { state = initialState; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchSeasons.fulfilled, 
        (state, { payload }) => {
          state.seasonsList = payload;
          state.selectedSeason = initialState.selectedSeason;
        }
      )
      .addCase(
        fetchRounds.fulfilled, 
        (state, { payload }) => {
          state.roundsList = payload;
          state.selectedRound = initialState.selectedRound;
        }
      );
  }
});

const sliceActions = {
  ...seasonsSlice.actions,
  fetchSeasons,
  fetchRounds
}

export const useSeasonsSlice = () => (
  useBasicSlice<SeasonsState, typeof sliceActions>('seasons', sliceActions)
);

export default seasonsSlice.reducer;