import React, { FC, useMemo } from 'react';
import { Icon, Popconfirm } from 'antd';
import moment from 'moment';
import L from 'lodash';

import { DateISOString } from '@/types/common';
import { WidgetFixtureFilterData } from '@/views/Demo/Demo.types';
import { DATE_FORMAT } from '@/constants/time';

interface CalendarCardProps {
  dateISO: DateISOString;
  fixtureData: Partial<WidgetFixtureFilterData>[];
  cardActive?: boolean;
  activeRowIdx?: number;
  onDayDelete?: (dateISO: DateISOString) => void;
  onRowSelect?: (dateISO: DateISOString, rowIdx: number) => void;
}

const CalendarCard: FC<CalendarCardProps> = ({
  dateISO, 
  cardActive,
  activeRowIdx, 
  fixtureData, 
  onDayDelete = L.noop, 
  onRowSelect = L.noop
}) => {
    const { dateInFormat, dayName } = useMemo(
      () => {
        const instance = moment(dateISO);
        return {
          dateInFormat: instance.format(DATE_FORMAT),
          dayName: instance.toString().split(' ')[0]
        }
      },
      [dateISO]
    );

    return (
        <div className={`day${cardActive ? ' active' : ''}`}>
          <div className="day-date">
            <span>{dayName}</span>
            <span>{dateInFormat}</span>
            <span className="day-delete">
              <Popconfirm
                title="Are you sure you want to clear this day's matches?"
                onConfirm={() => onDayDelete(dateISO)}
              >
                <Icon type="delete" title="Delete match" />
              </Popconfirm>
            </span>
          </div>
         
          {
            fixtureData.map((fixture, idx) => (
              <div
                key={`${dateISO}_${fixture?.eventUUID || idx}`}
                className={`day-match${L.eq(idx, activeRowIdx) ? ' active' : ''}`}
                onClick={() => onRowSelect(dateISO, idx)}
              >
                <span className="match">
                  {
                    (fixture?.homeTeamName && fixture?.awayTeamName)
                      ? `${fixture?.homeTeamName} - ${fixture?.awayTeamName}`
                      : '---'
                  }
                </span>
              </div>
            ))
          }
        </div>
      );
}

export default CalendarCard;
