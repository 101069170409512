import * as TYPES from '../actionTypes';

export const showSaveBar = (onSave) => (dispatch) => {
  dispatch({ type: TYPES.SAVE_BAR_SHOW, payload: onSave });
};

export const setResetFields = (onReset) => (dispatch) => {
  dispatch({ type: TYPES.SAVE_BAR_SET_RESET, payload: onReset });
};

export const hideSaveBar = () => (dispatch) => {
  dispatch({ type: TYPES.SAVE_BAR_HIDE });
};
