export const getEventResult = (event) => {
  if (!event.result) return [];

  const results = event.result.split(':');

  if (results && results.length > 1) {
    const homeResult = results[0].trim();
    const awayResult = results[1].trim();

    return [homeResult, awayResult];
  }

  return [];
};
