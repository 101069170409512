import React, { useState } from 'react';
import { Menu } from 'antd';

import { UPLOAD_MENU } from '../../constants/uploadMenu';

import Uploader from './Uploader';

import './Uploader.scss';

const UploaderSection = ({
  data = {},
  uploadMenu = UPLOAD_MENU,
  uploaderEnabled,
}) => {
  const [currentPage, setCurrentPage] = useState('1');

  const renderMenuItems = () => uploadMenu.map((item) => (
    <Menu.Item key={item.id}>
      {item.menuItemName}
    </Menu.Item>
  ));

  return (
    <section className="upload-section">
      <Menu
        onClick={(e) => setCurrentPage(e.key)}
        selectedKeys={[currentPage]}
      >
        {renderMenuItems()}
      </Menu>
      <Uploader
        data={data}
        id={currentPage}
        uploaderEnabled={uploaderEnabled}
      />
    </section>
  );
};

export default UploaderSection;
