import { getTeams } from '../../clients/DataProduct/common/teams';
import { api } from '../../utils/api';
import * as TYPES from '../actionTypes';

export const fetchClubs = (params) => async (dispatch) => {
  dispatch({ type: TYPES.CLUBS_CLEANED });

  try {
    const { data: payload } = await getTeams(params);
    dispatch({ type: TYPES.CLUBS_FETCHED, payload });
  } catch (error) {
    // error.handleGlobally();
  }
};

export const simpleFetchClubs = async(params) => {
  try {
    const { data: payload } = await getTeams(params);
    return payload;
  } catch (error) {
    // error.handleGlobally();
  }
};

export const selectClub = (club) => (dispatch) => {
  dispatch({ type: TYPES.CLUB_SELECT, payload: club });
};

export const updateClub = (club, data) => async (dispatch) => {
  try {
    const { status, data: payload } = await api.put(`/teams/${club.id}`, data);

    if (status === 200) {
      dispatch({ type: TYPES.CLUB_DISPLAYED_NAME_UPDATED, payload: payload.displayed_name });
    }
  } catch (error) {
    error.handleGlobally();
  }
};
