import connector from '@/clients/DataProduct/connector';
import { Area } from '@/clients/DataProduct/environments/types';
import { Team } from './types';

export type GetTeamsParams = Pick<Area, 'areaCode'>;
interface TeamsResponse { team: Team }

const tempResponseConverter = (data: TeamsResponse[]) => data.map(
  ({ team }) => ({
    ...team,
    id: team.uuid,
    contextType: 'team',
  })
);

export const getTeams = (params: GetTeamsParams) => connector<ReturnType<typeof tempResponseConverter>>({
  url: '/teams',
  method: 'GET',
  params,
  transformResponse: tempResponseConverter,
});
