import React from 'react';
import { Layout } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import { Header, Filters } from '../components';
import { FILTERS } from '../constants/filters';

const { Content: LayoutContent, Sider } = Layout;

export default (Component) => {
  const { logout } = useAuth0();

  const withLayout = (props) => {
    const { pathname } = props.history.location;

    const getFiltersList = () => {
      let filtersList = [];

      const filtersForPath = FILTERS[pathname.substr(1)];

      if (filtersForPath) {
        filtersList = filtersForPath;
      }

      return filtersList;
    };

    return (
      <Layout>
        <LayoutContent>
          <Header onLogout={() => logout({ returnTo: window.location.origin })} />

          <Layout>
            <Sider width={240}>
              <Filters filtersList={getFiltersList()} search={props.location.search} />
            </Sider>
            <div className="root-container">
              <Component {...props} />
            </div>
          </Layout>
        </LayoutContent>
      </Layout>
    );
  };

  return withLayout;
};
