import { api } from '../../utils/api';

import * as TYPES from '../actionTypes';

import { convertObjectKeysToUnderscore } from '../../utils/formatters';
import { GetFixtureParams, getFixtures, tempResponseConverter } from '@/clients/DataProduct/fixtures/events';
import { getCompetitions } from '@/clients/DataProduct/environments/competitions';
import connector from '@/clients/DataProduct/connector';

export const fetchTournaments = (areaCode, sportName) => async dispatch => {
  try {
    const { data: payload } = await getCompetitions({
      areaArea_code: areaCode,
      sportName
    });

    dispatch({ type: TYPES.TOURNAMENTS_FETCHED, payload });
  } catch(e) {
    e.handleGlobally();
  }
};

export const clearTournaments = () => dispatch => {
  dispatch({ type: TYPES.TOURNAMENTS_CLEAR });
};

export const selectTournament = tournamentId => dispatch => {
  dispatch({ type: TYPES.TOURNAMENT_SELECT, payload: tournamentId });
};

export const fetchTournamentFixtures = (season, date, team, status, limit) => async dispatch => {
  try {
    const { data } = await api.get(`/season/${season.id}/fixtures`, { date, team, status, limit });
    dispatch({ type: TYPES.TOURNAMENT_FIXTURES_FETCHED, payload: {
      fixtures: data.fixtures,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentFixturesDates = season => async dispatch => {
  try {
    const { data } = await api.get(`/season/${season.id}/dates`);
    dispatch({ type: TYPES.TOURNAMENT_FIXTURES_DATES_FETCHED, payload: {
      dates: data.dates,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentLeagueConfig = tournament => async dispatch => {
  try {
    const { data } = await api.get(`/tournament/${tournament.id}/config`);
    dispatch({ type: TYPES.TOURNAMENT_CONFIG_FETCHED, payload: {
      config: data.config,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentStandings = season => async dispatch => {
  try {
    const { data } = await api.get(`/tournament/${season.id}/standings`);
    dispatch({ type: TYPES.TOURNAMENT_STANDINGS_FETCHED, payload: data });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentTeamEntries = tournament => async dispatch => {
  try {
    const { data } = await api.get(`/tournament/${tournament.id}/teamEntries`);
    dispatch({ type: TYPES.TOURNAMENT_TEAM_ENTRIES_FETCHED, payload: {
      teamEntries: data.teamEntries,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentMatchDetails = (seasonId, fixtureId) => async dispatch => {
  try {
    const data = await api.getAll([
      `/season/${seasonId}/fixtures/${fixtureId}`,
      `/incidents/${fixtureId}`,
      `/details/${fixtureId}`,
    ]);
    dispatch({ type: TYPES.TOURNAMENT_MATCH_DETAILS_FETCHED, payload: data });
  } catch(error) {
    error.handleGlobally();
  }
};

export const saveTournamentData = tournamentData => async(dispatch, getState) => {
  const { filters: { season } } = getState();
  try {
    await api.put(`/tournament/${tournamentData.id}`, {
      tournament: { ...convertObjectKeysToUnderscore(tournamentData) },
      seasonId: season.id,
    });
    dispatch({ type: TYPES.TOURNAMENT_UPDATED, payload: tournamentData });
  } catch(error) {
    error.handleGlobally();
  }
};

export const saveLeagueConfigData = (tournamentUuid, leagueData) => async() => {
  try {
    await api.put(`/tournament/config/${tournamentUuid}`, {
      league: { ...convertObjectKeysToUnderscore(leagueData) },
    });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentRanking = areaCode => async dispatch => {
  try {
    const { data } = await api.get(`/tournament/ranking/${areaCode}`);
    dispatch({ type: TYPES.TOURNAMENT_RANKING_FETCHED, payload: data.ranking });
  } catch(e) {
    console.log(e);
  }
};

export const saveTournamentRanking = (areaCode, tournamentsList) => async() => {
  try {
    await api.post(`/tournament/ranking/${areaCode}`, {
      ranking: [ ...tournamentsList ],
    });
  } catch(error) {
    error.handleGlobally();
  }
};

export const fetchTournamentSeasons = tournament => async dispatch => {
  try {
    const { data } = await api.get(`/tournament/${tournament.id}/seasons`);
    dispatch({ type: TYPES.TOURNAMENT_SEASONS_FETCHED, payload: {
      seasons: data.seasons,
    } });
  } catch(error) {
    error.handleGlobally();
  }
};