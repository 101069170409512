import * as TYPES from '../actionTypes';

import { history } from '../index';
import { removeAllWhitespaces } from '../../utils/formatters';
import storage from '../../utils/localStorage';

const storageFilters = JSON.parse(storage.getItem('filters')) || {};

const removeDependedFilters = (key) => {
  const query = new URLSearchParams(history.location.search);
  const filtersDependency = [['package'], ['sport', 'area', 'tournament', 'season']];
  let filtersToBeCleared;

  if (query.has(key)) {
    filtersDependency.map((arr) => {
      const removedFilterIndex = arr.findIndex((filter) => filter === key);

      if (removedFilterIndex > -1) {
        const dependedFilters = arr.slice(removedFilterIndex + 1);

        dependedFilters.map((filter) => {
          query.delete(filter);
          delete storageFilters[filter];

          return null;
        });

        filtersToBeCleared = dependedFilters;
      }
      return null;
    });
  }
  return [filtersToBeCleared, query];
};

const addFilter = (key, value, query) => {
  const { pathname } = history.location;

  storageFilters[key] = value;
  storage.setItem('filters', JSON.stringify(storageFilters));
  history.push({
    pathname,
    search: query.toString(),
  });
};

export const setFilters = (key, value) => (dispatch) => {
  const [, query] = removeDependedFilters(key);

  query.set(key, removeAllWhitespaces(value.name));
  addFilter(key, value, query);
  dispatch({ type: TYPES.SET_FILTERS, payload: storageFilters });
};

export const setUrlFilters = (key, value) => (dispatch) => {
  const query = new URLSearchParams(history.location.search);

  query.set(key, removeAllWhitespaces(value.name));
  addFilter(key, value, query);
  dispatch({ type: TYPES.SET_URL_FILTERS, payload: { [key]: value } });
};

export const clearFilters = (key) => (dispatch) => {
  const { pathname } = history.location;
  const [filtersToBeCleared = [], query] = removeDependedFilters(key);

  filtersToBeCleared.push(key);
  delete storageFilters[key];
  query.delete(key);
  storage.setItem('filters', JSON.stringify(storageFilters));
  history.push(`${pathname}?${query.toString()}`);
  dispatch({ type: TYPES.CLEAR_FILTERS, payload: filtersToBeCleared });
};
