import * as TYPES from '../actionTypes';

import { api } from '../../utils/api';

export const addImage = (fileUrl, contextType) => (dispatch) => {
  switch (contextType) {
    case 'package':
      dispatch({ type: TYPES.IMAGE_PACKAGE_ADDED, payload: fileUrl });
      break;
    case 'area':
      dispatch({ type: TYPES.IMAGE_COUNTRY_ADDED, payload: fileUrl });
      break;
    case 'team':
      dispatch({ type: TYPES.IMAGE_CLUB_ADDED, payload: fileUrl });
      break;
    case 'competition':
      dispatch({ type: TYPES.IMAGE_TOURNAMENT_ADDED, payload: fileUrl });
      break;
    default:
  }
};

export const deleteImage = (fileName, contextType) => async (dispatch) => {
  try {
    await api.delete(`upload/${contextType}/${fileName}`);

    switch (contextType) {
      case 'package':
        dispatch({ type: TYPES.IMAGE_PACKAGE_DELETED });
        break;
      case 'area':
        dispatch({ type: TYPES.IMAGE_COUNTRY_DELETED });
        break;
      case 'team':
        dispatch({ type: TYPES.IMAGE_CLUB_DELETED });
        break;
      case 'competition':
        dispatch({ type: TYPES.IMAGE_TOURNAMENT_DELETED });
        break;
      default:
        return;
    }
  } catch (error) {
    error.handleGlobally();
  }
};
